import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {FakeDbService} from '@fake-db/fake-db.service';

import 'hammerjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {TranslateModule} from '@ngx-translate/core';
import {ContextMenuModule} from '@ctrl/ngx-rightclick';

import {CoreModule} from '@core/core.module';
import {CoreCommonModule} from '@core/common.module';
import {CoreSidebarModule, CoreThemeCustomizerModule} from '@core/components';
import {CardSnippetModule} from '@core/components/card-snippet/card-snippet.module';

import {coreConfig} from 'app/app-config';
import {AuthGuard} from 'app/auth/helpers/auth.guards';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {fakeBackendProvider} from 'app/auth/helpers'; // used to create fake backend
import {JwtInterceptor, ErrorInterceptor} from 'app/auth/helpers';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {ContentHeaderModule} from 'app/layout/components/content-header/content-header.module';

import {ContextMenuComponent} from 'app/main/extensions/context-menu/context-menu.component';
import {
    AnimatedCustomContextMenuComponent
} from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import {
    BasicCustomContextMenuComponent
} from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import {
    SubMenuCustomContextMenuComponent
} from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import {QuillModule} from 'ngx-quill';
import { AuthenticationService } from './auth/service';

  function appInitializer(authService: AuthenticationService) {
    return () => {
      return new Promise((resolve) => {
        authService.getUserByToken()
            .pipe(
                catchError((error) => {
                    // console.log('----', error);
                    authService.logout(); // Assume this method exists for session cleanup
                    return of(null); // Return an observable so the stream completes
                })
            )
            .subscribe()
            .add(resolve);
      });
    };
  }

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    }, {
        path: 'login',
        loadChildren: () => import('./main/auth/auth.module').then(m => m.AuthModule)
    }, {
        path: 'forget',
        loadChildren: () => import('./main/forget/forget.module').then(m => m.ForgetModule)
    }, {
        path: 'register',
        loadChildren: () => import('./main/register/register.module').then(m => m.RegisterModule)
    }, {
        path: 'dashboard',
        loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
    }, {
        path: 'setting',
        loadChildren: () => import('./main/setting/setting.module').then(m => m.SettingModule)
    }, {
        path: 'employee',
        loadChildren: () => import('./main/employee/employee.module').then(m => m.EmployeeModule)
    },
    {
        path: 'master',
        loadChildren: () => import('./main/master/master.module').then(m => m.MasterModule)
    },
    {
        path: 'operator',
        loadChildren: () => import('./main/operator/operator.module').then(m => m.OperatorModule)
    },
    {
        path: 'configuration',
        loadChildren: () => import('./main/configuration/configuration.module').then(m => m.ConfigurationModule)
    },
    {
        path: 'company',
        loadChildren: () => import('./main/company/company.module').then(m => m.CompanyModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'invoice',
        loadChildren: () => import('./main/invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
        path: 'invoice-batch',
        loadChildren: () => import('./main/invoiceBatch/invoiceBatch.module').then(m => m.InvoiceBatchModule)
    },
    {
        path: 'automatic-invoices',
        loadChildren: () => import('./main/automatic-invoices/automatic-invoices.module').then(m => m.AutomaticInvoicesModule)
    },
    {
        path: 'payroll',
        loadChildren: () => import('./main/payroll/payroll.module').then(m => m.PayrollModule)
    },
    {
        path: 'payroll-batch',
        loadChildren: () => import('./main/payrollBatch/payrollBatch.module').then(m => m.PayrollBatchModule)
    },
    {
        path: 'retention',
        loadChildren: () => import('./main/retention/retention.module').then(m => m.RetentionModule)
    },
    {
        path: 'retention-batch',
        loadChildren: () => import('./main/retentionBatch/retentionBatch.module').then(m => m.RetentionBatchModule)
    },
    {
        path: 'report',
        loadChildren: () => import('./main/report/report.module').then(m => m.ReportModule)
    },
    {
        path: 'profile',
        loadChildren: () => import('./main/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'certificate',
        loadChildren: () => import('./main/certificate/certificate.module').then(m => m.CertificateModule)
    },
    {
        path: 'change-password',
        loadChildren: () => import('./main/password/password.module').then(m => m.PasswordModule)
    },
    {
        path: 'pagenotfound',
        loadChildren: () => import('./main/pagenotfound/pagenotfound.module').then(m => m.PagenotfoundModule)
    }, {
        path: '**',
        loadChildren: () => import('./main/pagenotfound/pagenotfound.module').then(m => m.PagenotfoundModule) // Error 404 - Page not found
    }
];

@NgModule({
    declarations: [
        AppComponent,
        ContextMenuComponent,
        BasicCustomContextMenuComponent,
        AnimatedCustomContextMenuComponent,
        SubMenuCustomContextMenuComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy',
            useHash: true
        }),
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot(),
        ContextMenuModule,
        CoreModule.forRoot(coreConfig),
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CardSnippetModule,
        LayoutModule,
        ContentHeaderModule,
        QuillModule.forRoot()
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthenticationService],
        },
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        // ! IMPORTANT: Provider used to create fake backend, comment while using real API
        // fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
