import { Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DocumentCounterService {
  private sharedCFDIDocument = new BehaviorSubject<string>(localStorage.getItem('num_cfdi'));
  sharedCFDIDocument$ = this.sharedCFDIDocument.asObservable();

  constructor() {}

  updateCFDIDocument(newData: string) {
    this.sharedCFDIDocument.next(newData);
  }
}
