import { Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ApiCounterService {
    private sharedApiDocument = new BehaviorSubject<string>(localStorage.getItem('num_api'));
    sharedApiDocument$ = this.sharedApiDocument.asObservable();

    constructor() {}

    updateApiDocument(newData: string) {
        this.sharedApiDocument.next(newData);
    }
}
