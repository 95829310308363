export const locale = {
  lang: 'en',
  data: {
    LOGIN: {
      SUCCESSFULLY: 'You have successfully logged in.',
      REGISTERED_SUCCESSFULLY: 'You have registered successfully.',
      WELCOME: 'Welcome',
      WELCOME_LOGIN: 'Welcome to Factura360',
      ALREADY_ACCOUNT_EXIST: '¿Ya tienes una cuenta?',
      TITLE: 'Login',
      EMAIL: {
        LABEL: 'username',
        PLEASE_ENTER: 'Please enter your email address.',
        PLEASE_VALID: 'Please enter a valid email address.',
      },
      PASSWORD: {
        LABEL: 'password',
        PLEASE_ENTER: 'Please enter your password.',
      },
      REMEMBER: 'Remember Me',
      BUTTON: 'Log in',
      MESSAGES: {
        FAIL: 'Login Failed',
        ACCESS_DENIED: 'Username / Password Incorrect',
      },
    },
    FORGOT_PASSWORD: {
      TITLE: 'Forgot Password',
      BUTTON: 'Send Mail',
      SIGN_IN: 'Sign In',
      SIGN_UP: 'Sign Up',
      NEW_REGISTER: 'New on our platform',
      CUSTOMER: 'Download xml & pdf Customer/Employee',
    },
    REGISTER: {
      TITLE: 'Registration',
      FIRST_NAME: {
        LABEL: 'First name',
        PLEASE_ENTER: 'First name is required.',
      },
      LAST_NAME: {
        LABEL: 'Last name',
        PLEASE_ENTER: 'Last name is required.',
      },
      RFC: {
        LABEL: 'RFC',
        PLEASE_ENTER: 'RFC is required.',
        PLEASE_VALID: 'Invalid RFC.',
        ALREADY_EXIST: 'RFC already exist',
      },
      MOTHERS_LAST_NAME: {
        LABEL: 'Mothers last name',
        PLEASE_ENTER: 'Mothers last name is required.',
      },
      EMAIL: {
        LABEL: 'Email',
        PLEASE_ENTER: 'Email address is required.',
        PLEASE_VALID: 'Invalid email address.',
      },
      USERNAME: {
        LABEL: 'Username',
        PLEASE_ENTER: 'Username is required and must be 8 characters long.',
        PLEASE_VALID: 'Invalid username.',
      },
      RE_PASSWORD: {
        LABEL: 'Retype password',
        PLEASE_ENTER: 'Retype password is required.',
      },
      CURP: {
        LABEL: 'CURP',
        PLEASE_ENTER: 'CURP is required.',
        PLEASE_VALID: 'Invalid CURP.',
      },
      COUPON: {
        LABEL: 'Coupon',
        PLEASE_ENTER: 'Coupon is required.',
        PLEASE_VALID: 'Invalid Coupon.',
      },
      EXPIRY_DATE: {
        LABEL: 'Annuity expiration date',
        PLEASE_ENTER: 'Annuity expiration date is required.',
      },
      PASSWORD: {
        LABEL: 'Password',
        PLEASE_ENTER: 'Password is required.',
        CONFIRM_ERROR: 'Confirm Passwords do not match with password.',
        MIN_LENGTH: 'Password must be of minimum 6 characters',
      },
      TERM_OF_USE: {
        LABEL: 'Acepto los términos y condiciones del servicio',
        PLEASE_CHECK: 'Acepta las condiciones de uso',
        TEXT: 'Términos de uso',
      },
      BUTTON: 'Sign Up',
      MESSAGES: {
        FAIL: 'Registration Failed',
      },
    },
    ACCESS: {
      TITLE: 'Access Panel',
      MASTER_RFC: {
        LABEL: 'RFC Master',
        PLEASE_ENTER: 'RFC Master is required',
      },
      RECEPTOR_RFC: {
        LABEL: 'Receiver RFC',
        PLEASE_ENTER: 'RFC Master is required',
      },
      KEYWORD: {
        LABEL: 'Keyword',
        PLEASE_ENTER: 'Keyword is required',
      },
      FROM_DATE: {
        LABEL: 'From Date (opcional)',
        PLEASE_ENTER: 'From Date is required',
      },
      END_DATE: {
        LABEL: 'To Date (opcional)',
        PLEASE_ENTER: 'End Date is required',
      },
      ADD_RECORD: {
        LABEL: 'Add Record',
      },
      TYPE: {
        LABEL: 'Download Options',
      },
      BUTTON: 'Submit',
      MESSAGES: {
        FAIL: 'No data found!',
      },
    },
    TEXTBOX: {
      EDIT_CONCEPT_MESSAGE: 'Are you sure you want to edit this concept?',
      STATUS_SUCCESS: 'Success',
      STATUS_MESSAGE: 'All results can be viewed in your report.',
      PAID_MESSAGE: 'Payment data registration was successful.',
      STATUS: 'Status',
      SUCCESS: 'Success',
      CANCELLED: 'Cancelled',
      SHOW: 'Show',
      ADDRECORD: 'Add Records',
      EXPORTCSV: 'Export CSV',
      EXCEL: 'Export to Excel',
      Action: 'Action',
      ACTIVE: 'Active',
      INACTIVE: 'Inactive',
      SEARCH_ACTIVE: 'Current CFDI',
      SEARCH_INACTIVE: 'CFDI Canceled',
      BANKRFC: 'Bank RFC',
      INVOICECODE: 'Invoice Code',
      RETENTION_CODE: 'Retention Code',
      STATE: 'State',
      COUNTRY: 'Country',
      MUNICIPALITY: 'Municipality',
      LOCATION: 'Location',
      POSTALCODE: 'Postal Code',
      TITLE: 'Title',
      VIEW: 'View',
      APPLY: 'Apply',
      SEND: 'Send',
      DOWNLOAD_XML: 'Download XML',
      DOWNLOAD_PDF: 'Download PDF',
      DOWNLOAD_CANCEL_PDF: 'Download Cancel PDF',
      DOWNLOAD_CANCEL_ACKNOWLEDGMENT: 'Cancel Acknowledgment',
      DOWNLOAD: 'Download',
      SEND_MAIL: 'Send Mail',
      CLONE: 'Clone',
      PAYMENT_CLONE: 'Payment Clone',
      MODIFY_RECORD: 'Edit',
      EDIT: 'Edit',
      CANCEL_RECORD: 'Cancel',
      REMOVE: 'Remove',
      INVOICE_STATUS: 'CFDI Status',
      ADD_EMAIL: 'Add Email',
      EMAIL: 'Email',
      ADDITIONAL_MESSAGE: 'Additional Message',
      NO_RECORD: 'No record found',
      EMAIL_HEAD_MESSAGE: 'Email Head',
      EMAIL_BODY_MESSAGE: 'The document has been successfully sent to ',
      EMAIL_MODAL_TITLE: 'Send an email',
      CANCEL_MODAL_TITLE: 'Cancel retention',
      CFDI_CANCEL_MODAL_TITLE: 'Cancel invoice',
      PAYROLL_CANCEL_MODAL_TITLE: 'Cancel payroll',
      PASSWORD: 'Password',
      PASSWORD_ERROR: 'Password is required',
      INVALID_CERTIFICATE: 'Invalid Certificate Password',
      PAYROLL_ERROR1: 'Crossed company payroll series limit.',
      PAYROLL_ERROR2: 'Crossed Master\'s total Payroll.',
      INVOICE_ERROR2:
        'Your account has no documents available, Please purchase a new folio package',
      PAYROLL_ERROR3: 'Service Error',
      PAYROLL_ERROR4: 'Master has blocked.',
      PAYROLL_ERROR6: 'Invalid certificate password.',
      REMARK: 'Reason for cancellation',
      REMARK_ERROR: 'Reason for cancellation is required',
      UUID_SUBSTITUTE: 'UUID Substitute',
      STATUS_MODAL_TITLE: 'Status',
      CANCEL_HEAD_MESSAGE: 'Cancelled',
      CANCEL_BODY_MESSAGE: 'Record cancelled successfully',
      ERROR_PASSWORD_MESSAGE:
        'Incorrect billing password. If you recently uploaded CSD files, check the private key password as it is not the same one that the SAT has registered. Subsequently upload your CSD again in uploading certificates.',
      ERROR_PAID_MESSAGE: 'Error: No data',
      REMOVE_TITLE: 'Remove',
      REMOVE_MESSAGE: 'Are you sure you want to remove this record?',
      EDIT_TITLE: 'Edit',
      EDIT_MESSAGE: 'Are you sure want to edit this record?',
      CANCEL: 'Cancel',
      ACCEPT: 'Accept',
      DELETED: 'Deleted!',
      DELETED_MESSAGE: 'The record has been deleted.',
      SUCCESS_HEAD: 'Success',
      SUCCESS_RECORD_HEAD: 'Success',
      SUCCESS_MESSAGE: 'Record added successfully',
      NAME: 'Name',
      CODE: 'Code',
      EXCHANGE_RATE: 'Exchange Rate',
      NAME_ERROR: 'Name is required',
      RFC_ERROR: 'RFC is required',
      YEAR_ERROR: 'Year is required',
      CODE_ERROR: 'Code is required',
      COUNTRY_ERROR: 'Country is required',
      STATE_ERROR: 'State is required',
      POSTAL_CODE_ERROR: 'Postal Code is required',
      MUNICIPALITY_ERROR: 'Municipality is required',
      LOCATION_ERROR: 'Location is required',
      RETENTION_CODE_ERROR: 'Retention code is required',
      EXCHANGE_RATE_ERROR: 'Exchange Rate is required',
      START_ERROR: 'Start is required',
      END_ERROR: 'End is required',
      STATUS_ERROR: 'Status is required',
      DELETE_ALERT_HEAD: 'Delete',
      DELETE_ALERT_MESSAGE: 'Are you sure you want to delete this record?',
      DELETE_ALERT_CANCEL: 'Cancel',
      DELETE_ALERT_ACCEPT: 'Accept',
      DELETE_ALERT_DELETED: 'Deleted!',
      SEARCH: 'Search',
      SEARCH_TEXT: 'Search...',
      INTERNAL_SERVER_ERROR:
        'Internal server error, Please try again after sometime.',
      PAYROLL_DATE_ERROR:
        'Please check payment date, payment start date, end date and days paid.',
      PAYMENT_DATE_ERROR: 'Payment date is required.',
      PAYMENT_START_DATE_ERROR: 'Payment start date is required.',
      PAYMENT_END_DATE_ERROR: 'Payment end date is required.',
      PAYMENT_DETAIL_ADDED: 'Payment detail added.',
      PAYMENT_DETAIL_UPDATED: 'Payment detail updated.',
    },
    ALERT_MESSAGE: {
      PPD_HEADING: 'Alert',
      PPD_CONTENT: 'The PUE value is not normally used in payment supplements. The recommended value is PPD - Payment in partial payments or deferred.',
      ERROR_CONTENT: 'No data found',
      PPD_BUTTON: 'Ok',
      SPEI_MESSAGE: 'If this field exists, it is mandatory to register the Payment Certificate, Payment Chain and Digital Payment Stamp fields. If you do not have this information, do not use 01 - SPEI.',
      ERROR_MESSAGE_ORDER_ACCOUNT1: 'For the payment method Nominative check, the number of characters for the bank account is not correct, the accepted values are: Source account {11} {18}',
      ERROR_MESSAGE_ORDER_ACCOUNT2: 'For the payment method Electronic funds transfer, the number of characters for the bank account is not correct, the accepted values are: Source account {10} {16} {18}',
      ERROR_MESSAGE_ORDER_ACCOUNT3: 'For the payment method Credit card, the number of characters for the bank account is not correct, the accepted values are: Source account {16}',
      ERROR_MESSAGE_ORDER_ACCOUNT4: 'For the payment method Electronic wallet, the number of characters for the bank account is not correct, the accepted values are: Source account {10,11} {15,16} {18}',
      ERROR_MESSAGE_ORDER_ACCOUNT5: 'For the payment method Debit Card, the number of characters for the bank account is not correct, the accepted values are: Source account {16}',
      ERROR_MESSAGE_ORDER_ACCOUNT6: 'For the payment method Service card, the number of characters for the bank account is not correct, the accepted values are: Source account {16}',
      ERROR_MESSAGE_RECEIVE_ACCOUNT1: 'For the payment method Nominative check, the number of characters for the bank account is not correct, the accepted values are: Destination account {10.11} {15.16} {18}',
      ERROR_MESSAGE_RECEIVE_ACCOUNT2: 'For the payment method Electronic funds transfer, the number of characters for the bank account is not correct, the accepted values are: Destination account {10} {18}',
      ERROR_MESSAGE_RECEIVE_ACCOUNT3: 'For the payment method Credit card, the number of characters for the bank account is not correct, the accepted values are: Destination account {10.11} {15.16} {18}',
      ERROR_MESSAGE_RECEIVE_ACCOUNT4: 'For the payment method Electronic wallet, the number of characters for the bank account is not correct, the accepted values are: Destination account {10,11} {15,16} {18}',
      ERROR_MESSAGE_RECEIVE_ACCOUNT5: 'For the payment method Debit card, the number of characters for the bank account is not correct, the accepted values are: Destination account {10.11} {15.16} {18}',
      ERROR_MESSAGE_RECEIVE_ACCOUNT6: 'For the payment method Service card, the number of characters for the bank account is not correct, the accepted values are: Destination account {10.11} {15.16} {18}'
    },
    HEADER: {
      NAME: 'NAME',
      EMAIL: 'EMAIL',
      AGE: 'AGE',
      STATUS: 'STATUS',
      ACTIONS: 'ACTIONS',
    },
    SETTING: {
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      SAVE_TITLE: 'SAVE',
      PREVIEW: 'Preview',
      CANCEL_TITLE: 'CANCEL',
      VIEW: 'View',
      APPLY: 'Apply',
      SEND: 'Send',
    },
    SALES: {
      MEMBERSHIP_DATE: 'Annuity Expiry date',
      CERTIFICATE_DATE: 'Certificate/Key Expiry date',
      LAST_DOCUMENT: 'Last Issued CFDI Document',
      LAST_PAYROLL: 'Last Issued Payroll Document',
      LAST_RETENCIONES: 'Last Issued Retention Document',
      PURCHASE_WEB: 'Web Packages (Annuity/Folios)',
      PURCHASE_BATCH: 'Batch Packages (Annuity/Folios)',
      PURCHASE: 'Buy',
      TOTAL_SALES: 'Total Sales',
      ALL_TIME_EARNINGS: 'All Time Earnings',
      TOTAL_EARNINGS: 'Total Earnings',
      ORDERS: 'Orders',
      ORDER_STATUS: 'Order Status',
      LOW_STOCK: 'Low Stock',
      TOP_PRODUCT_CATEGORIES: 'Top Product Categories',
      DATE_UPDATED: 'Date Range Updated',
      REVENUE: 'Total Revenue',
      SALES_ORDERS: 'Sales Orders',
      UNITS_SOLD: 'Units Sold',
      RENEW_EXPIRE_DATE: 'Remember to renew your annuity that expires on',
      EXPIRE_CER_KEY_DATE:
        'Your certificates are about to expire, we recommend you renew them as soon as possible.',
      COMPANY_TIPS:
        'My Companies: Allows you to capture your company data for the tax documents that you will issue.',
      PRODUCT_TIPS:
        'Products: Create your products with the fields: unit of measure, price and description.',
      PART_TIPS:
        'Part: It allows to create the specifications of the products.',
      CUSTOMER_TIPS:
        'Customers: Capture RFC, name and fiscal address of your customers.',
      DEPARTMENT_TIPS:
        'Departments: Create a catalog with the names of your departments or areas of your business.',
      EXPENSE_TIPS:
        'Perceptions: Allows you to create all perceptions for payroll receipts.',
      DISCOUNT_TIPS:
        'Deductions: Allows you to create all deductions for the employee on a payroll receipt.',
      EMPLOYEE_TIPS:
        'Employees: Allows you to create records of your employees for the payroll.',
      OTHER_TIPS:
        'Other Payments: It allows to create registers of other type of perceptions like Subsidies, Viaticals, etc.',
      P_TIPS: 'Payroll Receipts: Opens the new payroll module.',
      P_B_TIPS:
        'Payroll Batch: Opens the module for issuing CFDI payroll receipts in bulk.',
      I_TIPS:
        'Invoice Opens the module to create a new invoice, receipt of fees, credit memo, etc.',
      I_B_TIPS:
        'Batch Invoices: Opens the module for issuing CFDI invoices in bulk.',
      R_TIPS: 'Retention Receipts: Opens the new Retention Receipt module.',
      R_B_TIPS:
        'Hold Batch: Opens the module for issuing CFDI hold receipts massively.',
      TOTAL_CFDI: 'Available CFDI',
      TOTAL_API: 'Available API/Batch',
      FULL_SCREEN: 'Full Screen',
      LANGUAGE: 'Language',
      PACKAGE: 'Buy your plan!',
      PAYPAL_HEAD: 'It is an easy, fast and secure means of payment.',
      PAYPAL_1: 'You can make payment by credit and debit card.',
      PAYPAL_2:
        'Register your financial information once in PayPal. It wont be necessary to collect them in future purchases.',
      PAYPAL_3: 'More than 100 million people in the world use PayPal.',
      PAYPAL_4:
        'PayPal protects your information: We do not share your financial information.',
      DATE: 'Date',
      RECEPTOR:
        'Retentions customers. Allow create receptors that can be use inside retentions CFDI.',
      USER: 'Users. Create users with specifics restrictions',
      TAX: 'Tax manager. You can create special percentages to can use inside invoice web form.',
      DASHBOARD_RECEPTOR: 'Receiver',
      DASHBOARD_TOTAL: 'Total',
      SERIE_FOLIO: 'Series/Folio',
      VERSION: 'Version',
      MASTER_MODULE: 'Master Users Manager',
      CURRENCY: 'Currency',
      WEB_SERVICE: 'Web Service',
      TAX_MODULE: 'Tax',
      EMPLOYEE_JOB_TYPE: 'Employee job type',
      EMPLOYEE_EXTRA_HOUR_TYPE: 'Employee hour type',
      COMPANY_MODULE: 'Company',
      PRODUCT_MODULE: 'Product Management',
      PART_MODULE: 'Part Management',
      CUSTOMER_MODULE: 'Customer Management',
      RETENTION_RECEPTOR: 'Retention Receptor',
      OPERATOR: 'Users',
      EMPLOYEE_DEPARTMENT: 'Department',
      EMPLOYEE: 'Employee',
      EMPLOYEE_EXPENSE: 'Employee Expense',
      EMPLOYEE_OTHER_TYPE: 'Employee Other Type',
      EMPLOYEE_OTHER: 'Employee Others',
      EMPLOYEE_DISCOUNT: 'Employee Discount',
      INVOICE: 'Invoice 4.0',
      PAYROLL: 'Payroll 4.0',
      RETENTION: 'Retention 2.0',
      INVOICE_BATCH: 'Invoice Batch 2.0',
      CFDI: 'Payroll batch 4.0',
      RETENTION_BATCH_SUB: 'Retention 2.0',
    },
    MASTER_PLAN_PURCHASE: {
        TITLE: 'Purchase Plan',
        PLAN: 'Plans',
        ADD_TITLE: 'Add Master Plan',
        MANAGE_TITLE: 'Purchase Plan',
        DATE: 'Date',
        PLAN_ID: 'Select Plan',
        PRICE: 'Price',
        AMOUNT: 'Amount',
        CFDI_DOCUMENTS: 'No of CFDI Documents',
        PAYROLL_DOCUMENTS: 'No ofPayroll Documents',
        API_DOCUMENTS: 'No of API Documents',
        PAYMENT_DOCUMENTS: 'No of Payment Documents',
        PAYMENT_METHOD: 'Select Payment Method',
        SELLER: 'Seller',
        NOTE: 'Note',
        PLAN_WEB: 'Web Plans',
        PLAN_BATCH: 'Batch Plans'
    },
    PROFILE: {
      PROFILE: 'Setting',
      MESSAGE: {
        SUCCESS: 'Success',
        ADD_SUCCESS: 'Master added successfully.',
        EDIT_SUCCESS: 'Master modified successfully.',
        EMAIL_EXISTS: 'Email already exist.',
        CUSTOMIZE: 'Email already exist.',
      },
      VALIDATION: {
        RFC: 'RFC is required',
        NAME: 'Name is required',
        EMAIL: 'Email is required',
        WHATSAPP: 'Whatsapp number is required',
        WEB_SERVICE_ID: 'Web service is required',
        ACTIVE_CHANGE_PASSWORD: 'Active password change is required',
        BANNER1_ALIGN: 'Banner Align is required',
        ACTIVE_BANNER1: 'Active first banner is required',
      },
      TITLE: 'Profile Settings',
      CHANGE_PASSWORD: 'Change Password',
      VIEW: 'View Plans',
      APPLY: 'Apply Plans',
      RFC: 'RFC',
      NAME: 'Full Name',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      USERNAME: 'Username',
      MOTHER_LAST_NAME: 'Mother Last Name',
      EMAIL: 'Email Address',
      CURP: 'CURP',
      VERIFIED: 'Verified',
      PLANS: 'Plans',
      WEB_SERVICE_ID: 'Select Web Service',
      CFDI_DOCUMENTS: 'No of CFDI Documents',
      PAYROLL_DOCUMENTS: 'No of Payroll Documents',
      API_USED_DOCUMENTS: 'No of Used API Documents',
      PAYMENT_USED_DOCUMENTS: 'No of Used Payment Documents',
      CFDI_USED_DOCUMENTS: 'No of Used CFDI Documents',
      API_DOCUMENTS: 'No of API Documents',
      PAYMENT_DOCUMENTS: 'No of Payment Documents',
      PURCHASES_CFDI: 'No of Purchased CFDI',
      PURCHASES_API: 'No of Purchases API ',
      PURCHASES_PAYMENT: 'No of Purchases Payment',
      DATE: 'Date',
      PLAN_ID: 'Plan',
      PRICE: 'Price',
      PAYMENT_METHOD: 'Select Payment Method',
      SELLER: 'Seller',
      NOTE: 'Note',
      EXPIRY_DATE: 'Expiration date',
      MANIFEST: 'Manifest',
      ACTIVE_MANIFEST: 'Active CMV',
      ACTIVE_PREMIUM: 'Activate premium web report',
      ACTIVE_PREMIUM_API: 'Activate premium batch report',
      ACTIVE_CFDI_PRIMARY: 'Activate primary CFDI Sector',
      ACTIVE_DONEE: 'Activate Donee',
      ACTIVE_DOWNLOAD_XML_API: 'Download old XML',
      ACTIVE_DOWNLOAD_PDF_API: 'Download old PDF',
      API_RESPONSE: 'API response',
      ACTIVE_MULTIPLE_PAYROLL: 'Activate multiple payroll',
      ACTIVE_MULTIPLE_PAYROLL_BATCH: 'Activate multiple payroll batch',
      BANNER: 'Banner',
      BANNER1: 'First banner',
      BANNER2: 'Second banner',
      BANNER1_ALIGN: 'Banner Align',
      BANNER2_ALIGN: 'Align',
      ACTIVE_BANNER1: 'Active first banner',
      ACTIVE_BANNER2: 'Active second banner',
      BOX1: 'Box 1',
      BOX1_NOTIFICATION: 'Box 1 notificación',
      BOX2: 'Box 1',
      BOX2_NOTIFICATION: 'Box 2 notificación',
      WHATSAPP: 'Whatsapp',
      ACTIVE_EXPIRY_NOTIFICATION: 'Active annuity expire',
      ACTIVE_CHANGE_PASSWORD: 'Active password change',
      ACTIVE_INVOICE_WITH_PERCENT: 'Activate Invoices with percentage',
      ACTIVE_INVOICE_WITH_PERCENT_BATCH:
        'Activate Invoices with percentage batch',
    },
    CHANGE_PASSWORD: {
      TITLE: 'Change password',
      VALIDATION: {
        CURRENT_PASSWORD: 'Current password is required.',
        NEW_PASSWORD: 'New password is required.',
        RETYPE_PASSWORD: 'Retype password is required.',
        NEW_CONFIRM_PASSWORD_ERR:
          'New password and confirm password are not same',
      },
      FIELDS: {
        CURRENT_PASSWORD: 'Current Password',
        NEW_PASSWORD: 'New Password',
        RETYPE_PASSWORD: 'Retype New Password',
        PASSWORD_LENGTH: 'Password must to contains at least 8 characters',
        PASSWORD_MUST_START_WITH:
          'Password must to start with A-Z or a-z and contains at least one number',
        PASSWORD_SPECIAL_CHARS: 'user can use special characters like @$!%*#?',
        NO_SPACE_ALLOW: 'Space do not allow',
        INVALID_PASSWORD:
          'The new password must start with a letter and include at least one number. You must use special characters like @$!%*#?',
        NEW_CONFIRM_PASSWORD_ERR:
          'New password and confirm password are not same',
      },
      MESSAGE: {
        FAIL: 'The current access password is not correct. please try again.',
        SUCCESS: 'Success',
        MESSAGE: 'Password changed successfully',
      },
    },
    CURRENCY: {
      HEADING: 'Currency',
      D: 'Currency Add',
      DI: 'Currency Edit',
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      SINGLE_NAME: 'Single Name (Spanish)',
      NAME: 'Name (Spanish)',
      SINGLE_NAME_EN: 'Single Name (English)',
      NAME_EN: 'Name (English)',
      CODE: 'Code',
      ENABLED: 'Enabled',
      EXCHANGE_RATE: 'Exchange Rate'
    },
    WEBSERVICE: {
      HEADING: 'Web Service',
      ADD: 'Web Service Add',
      EDIT: 'Web Service Edit',
      NAME: 'Name',
      ACTIVE: 'Status',
    },
    TAXMANAGER: {
      HEADING: 'Tax Manager',
      ADD: 'Tax Manager Add',
      EDIT: 'Tax Manager Edit',
    },
    PLAN: {
      HEADING: 'Plan',
      ADD: 'Plan Add',
      EDIT: 'Plan Edit',
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      NAME: 'Title',
      DOCUMENTCFDI: 'Documentos CFDI',
      DOCUMENTAPI: 'Documents API/batch',
      DOCUMENTPRICE: 'Documents Payment',
      PRICE: 'Precio',
      TYPE: 'Tipo',
      ACTIVEINACTIVE: 'Inactivo/Activo',
    },
    BANK: {
      HEADING: 'Banks',
      ADD: 'Bank Add',
      EDIT: 'Bank Edit',
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      NAME: 'Name',
      CODE: 'Code',
      BANKRFC: 'Bank RFC',
    },
    COLONY: {
      HEADING: 'Colonies',
      ADD: 'Colony Add',
      EDIT: 'Colony Edit',
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      NAME: 'Name',
      CODE: 'Code',
      POSTAL_CODE: 'Postal Code',
    },
    COUNTRY: {
      HEADING: 'Countries',
      ADD: 'Country Add',
      EDIT: 'Country Edit',
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      NAME: 'Name',
      CODE: 'Invoice Code',
      RETENTION_CODE: 'Retention Code',
    },
    STATE: {
      HEADING: 'States',
      ADD: 'State Add',
      EDIT: 'State Edit',
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      NAME: 'Name',
      CODE: 'Code',
      COUNTRY_NAME: 'Country',
    },
    LOCATION: {
      HEADING: 'Locations',
      ADD: 'Location Add',
      EDIT: 'Location Edit',
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      NAME: 'Name',
      CODE: 'Code',
      STATE: 'State',
    },
    MUNICIPALITY: {
      HEADING: 'Municipalities',
      ADD: 'Municipality Add',
      EDIT: 'Municipality Edit',
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      NAME: 'Name',
      CODE: 'Code',
      STATE: 'State',
    },
    POSTAL_CODE: {
      HEADING: 'Postal codes',
      ADD: 'Postal code Add',
      EDIT: 'Postal code Edit',
      EDIT_TITLE: 'EDIT',
      DELETE_TITLE: 'TRASH',
      CODE: 'Code',
      STATE: 'State',
      MUNICIPALITY: 'Municipality',
      LOCATION: 'Location',
    },
    TAX_OBJECT_CATEGORY: {
      HEADING: 'Tax object categories',
      ADD: 'Tax object Add',
      EDIT: 'Tax object Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Title',
      CODE: 'Code',
    },
    MONTH: {
      HEADING: 'Months',
      ADD: 'Month Add',
      EDIT: 'Month Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Title',
      CODE: 'Code',
    },
    PERIODICITY: {
      HEADING: 'Periodicities',
      ADD: 'Periodicity Add',
      EDIT: 'Periodicity Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Title',
      CODE: 'Code',
    },
    RETENTION_PAYMENT_TYPE: {
      HEADING: 'Retention payments',
      ADD: 'Retention payment Add',
      EDIT: 'Retention payment Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Title',
      CODE: 'Code',
    },
    YEAR: {
      HEADING: 'Years',
      ADD: 'Year Add',
      EDIT: 'Year Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Title',
    },
    RELATIONSHIP_TYPE: {
      HEADING: 'Relationship types',
      ADD: 'Relationship types Add',
      EDIT: 'Relationship types Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    EXPORT: {
      HEADING: 'Export',
      ADD: 'Export Add',
      EDIT: 'Export Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    AGENT_NUMBER_TYPE: {
      HEADING: 'Authorized agents',
      ADD: 'Authorized agent Add',
      EDIT: 'Authorized agent Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      START: 'Start',
      END: 'End',
      CODE: 'Code',
    },
    LOAD_TYPE: {
      HEADING: 'Load types',
      ADD: 'Load types Add',
      EDIT: 'Load types Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    BOAT_TYPE: {
      HEADING: 'Boat types',
      ADD: 'Boat types Add',
      EDIT: 'Boat types Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    AIR_TYPE: {
      ADD: 'Carrier code Add',
      EDIT: 'Carrier code Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    TRAILER_TYPE: {
      HEADING: 'Trailer or semi_trailer',
      ADD: 'Trailer or semi_trailer Add',
      EDIT: 'Trailer or semi_trailer Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    PERMISSION_TYPE: {
      HEADING: 'Type of permit provided by the SCT',
      ADD: 'Type of permit provided by the SCT Add',
      EDIT: 'Type of permit provided by the SCT Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    FEDERAL_TRUCKING_TYPE: {
      HEADING: 'Motor transports',
      ADD: 'Motor transport nomenclature key Add',
      EDIT: 'Motor transport nomenclature key Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    PACKAGING_UNIT: {
      HEADING: 'Measurement and packaging unit',
      ADD: 'Measurement and packaging unit Add',
      EDIT: 'Measurement and packaging unit Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    PACKAGING_TYPE: {
      HEADING: 'Packaging catalogs',
      ADD: 'Packaging catalog Add',
      EDIT: 'Packaging catalog Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    PRODUCT_STCC_CATEGORY: {
      HEADING: 'STCC product catalog',
      ADD: 'STCC product catalog Add',
      EDIT: 'STCC product catalog Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    HAZARDOUS_CATEGORY: {
      HEADING: 'Hazardous Materials Catalog',
      ADD: 'Hazardous Materials Catalog Add',
      EDIT: 'Hazardous Materials Catalog Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    STATION_LIST: {
      HEADING: 'Station lists',
      ADD: 'Station list Add',
      EDIT: 'Station list Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
      IDENTIFICATION_CODE: 'identification key',
    },
    STATION: {
      HEADING: 'Stations',
      ADD: 'Station Add',
      EDIT: 'Station Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    TRANSPORT: {
      HEADING: 'Transports',
      ADD: 'Transport Add',
      EDIT: 'Transport Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      TITLE: 'Title',
      CODE: 'Code',
    },
    MERCHANDISE_MEASURE: {
      HEADING: 'Unit of measure applicable to the merchandise',
      ADD: 'Unit of measure applicable to the merchandise Add',
      EDIT: 'Unit of measure applicable to the merchandise Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Title',
      CODE: 'Code',
    },
    TRANSFER_REASON: {
      HEADING: 'Transfer reasons',
      ADD: 'Transfer reason Add',
      EDIT: 'Transfer reason Edit',
      EDIT_TITLE: 'Editar',
      DELETE_TITLE: 'Borrar',
      NAME: 'Title',
      CODE: 'Code',
    },
    USE_CFDI: {
      HEADING: 'Use CFDI',
      TITLE: 'Title',
      CODE: 'Code',
      STATE: 'State',
      COUNTRY: 'Country',
      STATUS: 'Status',
      ADD: 'Use CFDI Add',
      EDIT: 'Use CFDI Edit',
    },
    PRODUCT_CATALOG: {
      HEADING: 'Product Catalog',
      CODE: 'Code',
      TITLE: 'State',
      START_DATE_VALIDITY: 'Start date validity',
      END_DATE_VALIDITY: 'End date validity',
      INCLUDE_IVA: 'Include iva',
      INCLUDE_IEPS: 'Include IEPS',
      INCLUDE_COMPLEMENT: 'Include complement',
      ADD: 'Product Catalog Add',
      EDIT: 'Product Catalog Edit',
    },
    MEASUREMENT: {
      HEADING: 'Measurement Unit Manager',
      CODE: 'Code',
      TITLE: 'Title',
      ENDDATE: 'End date',
      DISCRIPTION: 'Description',
      ADD: 'Measurement Unit Add',
      EDIT: 'Measurement Unit Edit',
    },
    ORIGIN_BANK: {
      HEADING: 'Manage Bank Origin & Destination',
      RFC: 'RFC',
      NAME: 'Name',
      BANK_ORIGIN: 'Activate bank origin',
      BANK_DESTINATION: 'Activate bank destination',
      ADD: 'Bank Origin & Destination Add',
      EDIT: 'Bank Origin & Destination Edit',
    },
    TEMPLATE: {
      HEADING: 'Manage Template',
      NAMEES: 'Name (Spanish)',
      NAMEEN: 'Name (English)',
      CONTENTES: 'Content (Spanish)',
      CONTENTEN: 'Content (English)',
      ADD: 'Template Add',
      EDIT: 'Template Edit',
    },
    FISCAL_EXERCISE: {
      HEADING: 'Fiscal Exercise',
      ADD: 'Fiscal Exercise Add',
      EDIT: 'Fiscal Exercise Edit',
      CODE: 'Code',
      NAME: 'Title',
      STATUS: 'Status',
    },
    EDUCATION_LEVEL: {
      HEADING: 'Education Level',
      ADD: 'Education Level Add',
      EDIT: 'Education Level Edit',
      TITLE: 'Title',
      STATUS: 'Status',
    },
    OPERATION: {
      HEADING: 'Operation',
      ADD: 'Operation Add',
      EDIT: 'Operation Edit',
      TITLE: 'Title',
      CODE: 'Code',
      STATUS: 'Status',
    },
    PETITION: {
      HEADING: 'Petition',
      ADD: 'Petition Add',
      EDIT: 'Petition Edit',
      TITLE: 'Title',
      CODE: 'Code',
      STATUS: 'Status',
    },
    INCOTERM: {
      HEADING: 'Incoterm',
      ADD: 'Incoterm Add',
      EDIT: 'Incoterm Edit',
      TITLE: 'Title',
      CODE: 'Code',
      STATUS: 'Status',
    },
    JOBTYPE: {
      HEADING: 'Employee Job Type',
      ADD: 'Employee Job Type Add',
      EDIT: 'Employee Job Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    EXTRAHOUR: {
      HEADING: 'Employee Extra Hour Type',
      ADD: 'Employee Extra Hour Type Add',
      EDIT: 'Employee Extra Hour Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    EMPLOYEEPAYMENTPERIODTYPE: {
      HEADING: 'Employee Payment Period Type',
      ADD: 'Employee Payment Period Type Add',
      EDIT: 'Employee Payment Period Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    EMPLOYEEJOBRISKTYPE: {
      HEADING: 'Employee Job Risk Type',
      ADD: 'Employee Job Risk Type Add',
      EDIT: 'Employee Job Risk Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    EMPLOYEEOFFICIALJOBTYPE: {
      HEADING: 'Employee Official Job Type',
      ADD: 'Employee Official Job Type Add',
      EDIT: 'Employee Official Job Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    EMPLOYEEEXPENSETYPE: {
      HEADING: 'Employee Expense Type',
      ADD: 'Employee Expense Type Add',
      EDIT: 'Employee Expense Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    EMPLOYEECONTRACTTYPE: {
      HEADING: 'Employee Contract Type',
      ADD: 'Employee Contract Type Add',
      EDIT: 'Employee Contract Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    EMPLOYEEDISABILITYTYPE: {
      HEADING: 'Employee Disability Type',
      ADD: 'Employee Disability Type Add',
      EDIT: 'Employee Disability Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    EMPLOYEEOTHERTYPE: {
      HEADING: 'Employee Other Type',
      ADD: 'Employee Other Type Add',
      EDIT: 'Employee Other Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    EMPLOYEEDISCOUNTTYPE: {
      HEADING: 'Employee Discount Type',
      ADD: 'Employee Discount Type Add',
      EDIT: 'Employee Discount Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    RETENTIONKEY: {
      HEADING: 'Retention Key',
      ADD: 'Retention Key Add',
      EDIT: 'Retention Key Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    DISTRIBUTEDINCOMETYPE: {
      HEADING: 'Retention Distributed Income Key',
      ADD: 'Retention Distributed Income Key Add',
      EDIT: 'Retention Distributed Income Key Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    FEDERALENTITY: {
      HEADING: 'Retention Federal Entity',
      ADD: 'Retention Federal Entity Add',
      EDIT: 'Retention Federal Entity Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    RETENTIONTAXTYPE: {
      HEADING: 'Retention Tax Type',
      ADD: 'Retention Tax Type Add',
      EDIT: 'Retention Tax Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    TAXPAYERSUBJECTTYPE: {
      HEADING: 'Retention Tax Payer Subject Type',
      ADD: 'Retention Tax Payer Subject Type Add',
      EDIT: 'Retention Tax Payer Subject Type Edit',
      NAME: 'Name',
      CODE: 'Code',
    },
    MASTER: {
      HEADING: 'Users',
      HEADINGEXPIRED: 'Expired Masters',
      FIRSTNAME: 'First Name',
      RFC: 'RFC',
      USERNAME: 'username',
      NOOFCFDIDOCUMENTS: 'No of CFDI Documents',
      NOOFAPIDOCUMENTS: 'No of API Documents',
      NOOFPAYMENTDOCUMENTS: 'No of Payment Documents',
      COUPON: 'Coupon',
      VERIFIED: 'Verified',
      EXPIRATIONDATE: 'Expiration date',
      NOOFPURCHASEDCFDI: 'No of Purchased CFDI',
      NOOFPURCHASESAPI: 'No of Purchases API',
      NOOFPURCHASESPAYMENT: 'No of Purchases Payment',
      NOOFUSEDCFDIDOCUMENTS: 'No of Used CFDI Documents',
      NOOFUSEDAPIDOCUMENTS: 'No of Used API Documents',
      NOOFUSEDPAYMENTDOCUMENTS: 'No of Used Payment Documents',
      EDIT: 'Edit',
      DELETE: 'Delete',
      VIEWPLANS: 'View Plans',
      VIEWPLANHEADING: 'View Purchased Plans',
      APPLYPLANS: 'Apply Plans',
      CHANGEPASSWORD: 'Change Password',
      CHANGEPASSWORDBTN: 'Change',
      NEWPASSWORD: 'New Password',
      CONFIRMPASSWORD: 'Retype New Password',
      SENDEMAIL: 'Send email',
      ADDPURCHASEPLAN: 'Add Purchased Plans',
      SELECTPLAN: 'Select Plan',
      PASSWORDLENGTH: 'Password must to contains at least 8 characters',
      PASSWORDALPHA:
        'Password must to start with A_Z or a_z and contains at least one number',
      USERSPEACIALCHAR: 'user can use special characters like @$!%*#?&',
      MESSAGE: {
        ADD_SUCCESS: 'Master Added Successfully',
        EDIT_SUCCESS: 'Master Edited Successfully',
        EMAIL_EXISTS: 'Email Already exist',
        CUSTOMIZE: 'Email Already exist',
      },
    },
    MASTER_FIELD: {
      CREATE: 'Create',
      EDIT: 'Update',
      USER_CREATE: 'Master Create',
      USER_EDIT: 'Master Edit',
      YES: 'Yes',
      NO: 'No',
      XML: 'XML',
      XMLPDF: 'XML & PDF',
      CHANGE_PASSWORD: 'Change Password',
      VIEW: 'View Plans',
      APPLY: 'Apply Plans',
      RFC: 'RFC',
      NAME: 'Full Name',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      USERNAME: 'Username',
      MOTHER_LAST_NAME: 'Mother Last Name',
      EMAIL_ADDRESS: 'Email Address',
      CURP: 'CURP',
      VERIFIED: 'Verified',
      SELECTPAYMENT: 'Select Payment Method',
      WEB_SERVICE_ID: 'Select Web Service',
      CFDI_DOCUMENTS: 'No of CFDI Documents',
      PAYROLL_DOCUMENTS: 'No of Payroll Documents',
      API_USED_DOCUMENTS: 'No of Used API Documents',
      PAYMENT_USED_DOCUMENTS: 'No of Used Payment Documents',
      CFDI_USED_DOCUMENTS: 'No of Used CFDI Documents',
      API_DOCUMENTS: 'No of API Documents',
      PAYMENT_DOCUMENTS: 'No of Payment Documents',
      PURCHASES_CFDI: 'No of Purchased CFDI',
      PURCHASES_API: 'No of Purchases API ',
      PURCHASES_PAYMENT: 'No of Purchases Payment',
      DATE: 'Date',
      PLAN_ID: 'Plan',
      PRICE: 'Price',
      PAYMENT_METHOD: 'Select Payment Method',
      SELLER: 'Seller',
      NOTE: 'Note',
      EXPIRY_DATE: 'Expiration date',
      MANIFEST: 'Manifest',
      ACTIVE_MANIFEST: 'Active CMV',
      ACTIVE_PREMIUM: 'Activate premium web report',
      ACTIVE_PREMIUM_API: 'Activate premium batch report',
      ACTIVE_CFDI_PRIMARY: 'Activate primary CFDI Sector',
      ACTIVE_DONEE: 'Activate Donee',
      ACTIVE_DOWNLOAD_XML_API: 'Download old XML',
      ACTIVE_DOWNLOAD_PDF_API: 'Download old PDF',
      API_RESPONSE: 'API response',
      ACTIVE_MULTIPLE_PAYROLL: 'Activate multiple payroll',
      ACTIVE_MULTIPLE_PAYROLL_BATCH: 'Activate multiple payroll batch',
      BANNER: 'Banner',
      BANNER1: 'First banner',
      BANNER2: 'Second banner',
      BANNER1_ALIGN: 'Banner Align',
      BANNER2_ALIGN: 'Align',
      ACTIVE_BANNER1: 'Active first banner',
      ACTIVE_BANNER2: 'Active second banner',
      BOX1: 'Box 1',
      BOX1_NOTIFICATION: 'Box 1 notificación',
      BOX2: 'Box 1',
      BOX2_NOTIFICATION: 'Box 2 notificación',
      WHATSAPP: 'Whatsapp',
      ACTIVE_EXPIRY_NOTIFICATION: 'Active annuity expire',
      ACTIVE_CHANGE_PASSWORD: 'Active password change',
      ACTIVE_INVOICE_WITH_PERCENT: 'Activate Invoices with percentage',
      ACTIVE_INVOICE_WITH_PERCENT_BATCH: 'Activate Invoices with percentage batch',
      ACTIVATE_INVOICE: 'Invoice Activate',
      ACTIVATE_PAYMENT: 'CFDI Payments Activate',
      PAYROLL_ACTIVATE: 'Payroll Activate',
      RETENTIONSACTIVATE: 'Retentions Activate',
      NOTARYACTIVATE: 'Notary Activate',
      ACTIVATEEDUCATIONALSERIECFDI: 'Activate Educational CFDI',
      ACTIVATEUSEDCARSSERIECFDI: 'Activate Used Cars CFDI',
      ACTIVATEINESERIECFDI: 'Activate INE Serie CFDI',
      ACTIVATECONSTRUCTIONPARTITIONCFDI: 'Activate Construction Partition CFDI',
      ACTIVARFOREIGNTRADECFDI: 'Activar Foreign Trade CFDI 2.0',
      ACTIVATEPREMIUMWEBREPORT: 'Activate premium web report',
      ACTIVATEPREMIUMBATCHREPORT: 'Activate premium batch report',
      ACTIVATEPRIMARYCFDISECTOR: 'Activate primary CFDI Sector',
      ACTIVATEDONEE: 'Activate Donee',
      ACTIVATECARTAPORTE: 'Activate Carta Porte 3.1',
      DOWNLOADOLDXML: 'Download old XML',
      DOWNLOADOLDPDF: 'Download old PDF',
      ACTIVATECREDITNOTESSECTION: 'Activate Credit Notes section',
      APIRESPONSE: 'API response',
      ACTIVATEMULTIPLEPAYROLL: 'Activate multiple payroll',
      ACTIVATEMULTIPLEPAYROLLBATCH: 'Activate multiple payroll batch',
      ACTIVATEINVOICESWITHPERCENTAGE: 'Activate Invoices with percentage',
      ACTIVATEINVOICESWITHPERCENTAGEBATCH:
        'Activate Invoices with percentage batch',
      ACTIVE_INVOICE_WEB_3: 'Activate invoice web 3.3',
      ACTIVE_INVOICE_API_3: 'Activate invoice batch 3.3',
      ACTIVE_PAYROLL_WEB_3: 'Activate payroll web 3.3',
      ACTIVE_PAYROLL_API_3: 'Activate payroll batch 3.3',
      ACTIVE_RETENTION_WEB_3: 'Activate retention web 1.0',
      ACTIVE_RETENTION_API_3: 'Activate retention batch 1.0',
    },
    COMPANY: {
      TITLE: 'Company',
      ADD_TITLE: 'Add Company',
      EDIT_TITLE: 'Edit Company',
      ADD_MORE: 'Add More',
      MANAGE_TITLE: 'Manage',
      NEW: 'New',
      EDIT: 'Edit',
      DELETE: 'Delete',
      ERROR: {
        INTERNAL_NAME: 'Name is required.',
        NAME: 'Company Name is required.',
        POSTAL_CODE_ID: 'Postal Code is required.',
        COLONY: 'Colony is required.',
        LOCATION: 'Location is required.',
        DELEGATION_OR_MUNICIPALITY: 'Delegation or Municipality is required.',
        STATE: 'State is required.',
        BOARD_TYPE: 'Board Type is required.',
      },
      FIELDS: {
        SELECT_FILE: 'Select a file',
        IMAGE_FILE:
          'The file name must not have spaces. Supported image type: JPG, PNG. Maximum size of 800 Kb',
        BATCH_FILE: 'The file name must not have spaces. Supported type: txt, xml.',
        SUGGESTED_LABEL: 'Featured',
        UPLOAD_LABEL: 'Upload Image',
        UPLOAD: 'Select a File',
        CANCEL_UPLOAD: 'Cancel Upload',
        INTERNAL_NAME: 'Internal Name',
        NAME: 'Company Name',
        BUSINESS_NAME: 'Business Name',
        CURP: 'Curp',
        STREET: 'Street Address',
        EXTERIOR_NUMBER: 'Exterior Number',
        INTERIOR_NUMBER: 'Interior Number',
        COLONY: 'Colony',
        LOCATION: 'Location',
        ADDRESS: 'New Address',
        ADDRESS_REFERENCE: 'Address Reference',
        DELEGATION: 'Delegation or Municipality',
        MAYER_OR_MUNICIPALITY: 'Mayer or Municipality',
        COUNTRY: 'Country',
        STATE: 'State',
        OTHER_STATE: 'Other State',
        POSTAL_CODE: 'Postal Code',
        TELEPHONE: 'Telephone',
        EMAIL_ADDRESS: 'Email Address',
        WEBSITE: 'Website',
        USE_BRANCH: 'Branch Address',
        BRANCH_LABEL: 'Company Branch Address',
        ACTIVE: 'Active',
        AUTOMATIC_MAIL: 'Send Mail',
        LOGO: 'Logo',
        BOARD_TYPE: 'Board Type',
        ACTIVATE_INVOICE: 'Invoice Activate',
        ACTIVATE_PAYMENT: 'Activate CFDI Payments',
        ACTIVATE_PAYROLL: 'Payroll Activate',
        ACTIVATE_NOTARY: 'Notary Activate',
        ACTIVATE_RETENTIONS: 'Retentions Activate',
        INVOICE_FORMAT: 'Invoice Format',
        INVOICE_SERIES: 'Invoice series',
        INVOICE_START: 'Start',
        INVOICE_END: 'End',
        PAYROLL_SERIES: 'Payroll series',
        PAYROLL_START: 'Payroll start',
        PAYROLL_END: 'Payroll end',
        RETENTIONS_SERIES: 'Retentions series',
        RETENTIONS_START: 'Retentions start',
        RETENTIONS_END: 'Retentions end',
        NOTARY_CURP: 'Notary CURP',
        NOTARY_NUMBER: 'Notary Number',
        HTML_TEMPLATE: 'Template',
        ENABLED: 'Enabled',
        ACTIVATE_EDUCATION: 'Activate Educational Serie CFDI',
        ACTIVATE_USED_CARS: 'Activate Used Cars Serie CFDI',
        ACTIVATE_INE: 'Activate INE Serie CFDI',
        ACTIVATE_CONSTRUCTION_PARTITION: 'Activate Construction Partition CFDI',
        ACTIVATE_FOREIGN_TRADE: 'Activate Foreign Trade CFDI 2.0',
        ACTIVATE_DONEE: 'Activate Donee',
        FEDERAL_ENTITY: 'Federal Entity',
        AFFILIATION: 'Affiliation',
        SEE_DESIGN: 'See Design',
        IS_ENGLISH: 'Generate English PDF for this types of document',
        ACTIVATE_CARRY_LETTER: 'Activate Carta Porte 1.0',
        ACTIVATE_CARTA_PORTE2: 'Activate Carta Porte 2.0',
        ACTIVATE_CARTA_PORTE3: 'Activate Carta Porte 3.1',
        ACTIVATE_CREDIT_NOTE: 'Activate Credit Notes section',
        INVOICE_TRANSACTION_NUMBER:
          'Invoice transaction number through the acquirer',
        PAYMENT_TRANSFER: 'Payment Transfer',
        ACTIVATE_FOREIGN_TRADE_2: 'Activate Foreign Trade CFDI',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Company Added Successfully',
        EDIT_SUCCESS: 'Company Edited Successfully',
      },
    },
    AUTOMATIC_3: {
      SUCCESS: 'A. I. 3.3',
      CANCELLED: 'Cancelled A. I. 3.3',
      FAILED: 'Failed A. I. 3.3',
    },
    AUTOMATIC: {
      NEW: 'New A. I. 4.0',
      SUCCESS: 'A. I. 4.0',
      CANCELLED: 'Cancelled A. I. 4.0',
      FAILED: 'Failed A. I. 4.0',
      REPORT_HEADING: 'Reports',
      BATCH_REPORT: 'FA Reports',
      PAYMENT_BATCH_REPORT: 'FA Payment Reports',
      DOWNLOAD_BATCH_REPORT: 'Download F.A.',
      DOWNLOAD_SPECIAL_REPORT: 'Special Reports',
      TYPE: 'Query type',
      FAILED_BATCH_REPORT: 'Failed Reports',
      FAILED_WEB_INVOICE: 'Invoice web failed report',
      FAILED_WEB_PAYROLL: 'Payroll web failed report',
      FAILED_WEB_RETENTION: 'Retention web failed report',
      FAILED_BATCH_INVOICE: 'Invoice batch failed report',
      FAILED_BATCH_PAYROLL: 'Payroll batch failed report',
      FAILED_BATCH_RETENTION: 'Retention batch failed report',
      EXPORT_TO_EXCEL: 'Export to Excel',
    },
    INVOICE_3: {
      INVOICE: 'Invoice 3.3',
      LATEST_INVOICE: 'Latest Invoice 3.3',
      LATEST_INVOICE_DRAFT: 'Latest Invoice Draft 3.3',
      INVOICE_BATCH: 'Invoice Batch',
      INVOICE_BATCH_MODULE: 'Invoice Batch',
      INVOICE_DRAFT: 'Invoice Draft 3.3',
      CANCELLED_INVOICE: 'Cancelled Invoice 3.3',
      CANCELLED_LATEST_INVOICE: 'Cancelled Latest Invoice 3.3',
      FAILED_INVOICE: 'Failed Invoice 3.3',
      FAILED_LATEST_INVOICE: 'Failed Latest Invoice 3.3',
      CANCELLED_INVOICE_BATCH: 'Cancelled Batch 3.3',
      FAILED_INVOICE_BATCH: 'Failed Batch 3.3',
      BULK_INVOICE: 'Download Invoice',
      BULK_INVOICE_BATCH: 'Download Invoice Batch',
      ADDENDA: 'Addenda',
      INVOICE_REPORT: 'Invoice Report',
      INVOICE_BATCH_REPORT: 'Invoice Batch Report',
      INVOICE_PAYMENT_REPORT: 'Invoice Payment Report',
      INVOICE_PAYMENT_BATCH_REPORT: 'Invoice Payment Batch Report',
      INVOICE_PAYMENT_MENU: 'Invoice Payment Report',
      INVOICE_PREMIUM_MENU: 'CFDI complement payment',
      INVOICE_PREMIUM_BATCH_MENU: 'Report Plus Web Payments',
      INVOICE_PAYMENT_BATCH_MENU: 'Report Plus Batch Payments',
      NEW_INVOICE: 'New Invoice',
      NEW_TAX_INVOICE: 'New Invoice with Taxes',
      NEW_BATCH: 'New Invoice Batch',
      NEW_STATIC_BATCH: 'New Manual Batch Invoice',
      GET_CFDI_RESPONSE: 'CFDI status',
      TITLE: 'Invoice',
      ADD_TITLE: 'Add Invoice',
      EDIT_TITLE: 'Edit Invoice',
      DELETE_TITLE: 'Delete Invoice',
      CANCEL_CONFIRMATION: 'Get CFDI Status',
      PAID_TITLE: 'Paid Invoice',
      MANAGE_TITLE: 'Administrator',
      DRAFT_TITLE: 'Draft Invoice',
    },
    INVOICE_PERCENT: {
      INVOICE: 'Invoice IEPS 4.0',
      INVOICE_DRAFT: 'Invoice Draft IEPS 4.0',
      CANCELLED_INVOICE: 'Cancelled Invoice IEPS 4.0',
      FAILED_INVOICE: 'Failed Invoice IEPS 4.0',
    },
    INVOICE: {
      LABEL: {
        DRAFT: 'Draft',
        ISSUE_PAYROLL: 'Receipt Issue',
        ISSUE_CFDI: 'CFDI Issue',
        EDUCATION: 'Educational institution complement',
        INE: 'INE complement',
        USED_CAR: 'Used car complement',
        CONSTRUCTION: 'Partial construction services complement',
        NOTARY: 'Notaries complement',
        FOREIGN: 'Foreign trade complement',
        DONEE: 'Donated Complement',
        CARRY_LETTER: 'Bill complement',
        CARRY_LETTER3: 'Bill complement 3.1',
        COMMERCIAL_COMPLEMENT_2: 'Commercial complement 2.0',
        CONCEPT: 'Conceptos',
        SUBDIVISION_NO: 'It has no subdivision',
        SUBDIVISION_YES: 'If you have subdivision',
        CERTIFICATE_ORIGIN_NO: 'It does not act as a certificate of origin',
        CERTIFICATE_ORIGIN_YES: 'It acts as a certificate of origin',
        ORDINARY: 'Ordinario',
        PRE_CAMPAIGN: 'Precampaña',
        CAMPAIGN: 'Campaña',
        NATIONAL_EXECUTIVE: 'Ejecutivo Nacional',
        STATE_EXECUTIVE: 'Ejecutivo Estatal',
        STATE_MANAGER: 'Directivo Estatal',
        LOCAL: 'Local',
        FEDERAL: 'Federal',
        STATE: 'State',
        MATTER_TYPE_ID: 'Matter type',
        MATTER_DETAILS: 'Details of subject',
      },
      RELATED_SERIES: 'Related CFDI Series',
      RELATED_FOLIO: 'Related CFDI folio',
      RELATED_EXCHANGE_RATE: 'Exchange rate or equivalence',
      ITMO_REGISTRATION: 'ISTMO Registration',
      ITMO_REGISTRATION_ORIGIN: 'Location of the origin pole',
      ITMO_REGISTRATION_DESTINATION: 'Location of the destination pole',
      INGREDIENT_NAME: 'Active ingredient name',
      CHEMICAL_NAME: 'Chemical name',
      ACTIVE_MEDICINE_SUBSTANCE: 'Active substance of the medicine',
      MEDICINE_BRAND: 'Brand under which the medicine is promoted',
      MEDICINE_MANUFACTURER: 'Drug manufacturer',
      MEDICATION_EXPIRATION_DATE: 'Medication expiration date',
      MEDICATION_LOT_NUMBER: 'Medication Lot Number',
      PHARMACEUTICAL_FORM: 'Pharmaceutical form of the medicine',
      MEDICINE_TRANSFER_CONDITION_ID: 'Medicine transfer condition',
      TRANSPORT_TRANSFER_CONDITION_ID: 'Special transfer condition',
      COMPANY_HEALTH_RECORD: 'Health record of the transport company',
      IMPORT_MEDICINE_PERMIT: 'Import permit for the medicine',
      VUCEM_IMPORT_FOLIO: 'VUCEM import folio',
      CAS_CHEMICAL_COMPOUND_NUMBER: 'CAS chemical compound number',
      TOXIC_SUBSTANCE: 'Name of the importer of toxic substances',
      HEALTH_REGISTRATION_NUMBER: 'Health registration number',
      MANUFACTURER_NAME: 'Country and name of the manufacturer of the active ingredient of the pesticide or fertilizer',
      INGREDIENT_ACTIVE_NAME: 'Country and name of the formulator of the active ingredient of the pesticide or fertilizer',
      INGREDIENT_MAQUILADORA_COUNTRY_NAME: 'Country and name of the maquiladora of the active ingredient of the pesticide or fertilizer',
      PESTICIDE_AUTHORIZED: 'Authorized use of the pesticide or fertilizer',
      RETURN_MERCHANDISE: 'Is it a collection for return of merchandise?',
      CUSTOM_REGIMES: 'Customs regime',
      ADD_CUSTOM_REGIME: 'Add Regime',
      CUSTOM_REGIME_ID: 'Custom regime',
      SECTOR_HEADING: 'COFEPRIS sector',
      SECTOR_ID: 'COFEPRIS sector code',
      CUSTOMS_DOCUMENTATION: 'Customs Documentation',
      CUSTOM_DOCUMENT_ID: 'Custom document',
      DOCUMENT_REQUIREMENT_NUMBER: 'Requirement number',
      DOCUMENT_FOLIO: 'Customs document folio',
      DOCUMENT_RFC: 'RFC of the importer of the goods',
      DOCUMENT_ADD: 'Add customs document',
      GROSS_VEHICLE_WEIGHT: 'Gross Vehicle Weight',
      USED_CAR_LEVEL: 'NIV',
      FREE_TEXT_PLACEHOLDER: 'Enter your description',
      CHECK_THIRD_PARTY: 'On behalf of third parties',
      THIRD_PARTY_RFC: 'RFC on behalf of third parties',
      THIRD_PARTY_ACCOUNT_NAME: 'Name to third party account',
      THIRD_PARTY_TAX: 'Tax Regime on behalf of third parties',
      THIRD_PARTY_TAX_RESIDENCE: 'Fiscal domicile on behalf of third parties',
      EXPORT: 'Export',
      GLOBAL_INFORMATION: 'Global Information',
      PERIODICITY_ID: 'Periodicity',
      MONTH_ID: 'Month',
      YEAR: 'Year',
      SEND: 'Send',
      INVOICE: 'Invoice 4.0',
      LATEST_INVOICE: 'Latest Invoice 4.0',
      LATEST_INVOICE_DRAFT: 'Latest Invoice Draft 4.0',
      INVOICE_BATCH: 'Invoice Batch',
      INVOICE_BATCH_ADD: 'Add invoice Batch',
      INVOICE_BATCH_IEPS_ADD: 'Add New Invoice IEPS Batch 4.0',
      INVOICE_BATCH_MODULE: 'Invoice Batch',
      INVOICE_DRAFT: 'Invoice Draft 4.0',
      CANCELLED_INVOICE: 'Cancelled Invoice 4.0',
      CANCELLED_LATEST_INVOICE: 'Cancelled Latest Invoice 4.0',
      FAILED_INVOICE: 'Failed Invoice 4.0',
      FAILED_LATEST_INVOICE: 'Failed Latest Invoice 4.0',
      CANCELLED_INVOICE_BATCH: 'Cancelled Batch',
      FAILED_INVOICE_BATCH: 'Failed Batch',
      BULK_INVOICE: 'Download Invoice',
      BULK_INVOICE_BATCH: 'Download Invoice Batch',
      ADDENDA: 'Addenda',
      INVOICE_REPORT: 'Invoice Report',
      INVOICE_BATCH_REPORT: 'Invoice Batch Report',
      INVOICE_PAYMENT_REPORT: 'Invoice Payment Report',
      INVOICE_PAYMENT_BATCH_REPORT: 'Invoice Payment Batch Report',
      INVOICE_PAYMENT_MENU: 'Invoice Payment Report',
      INVOICE_PREMIUM_MENU: 'CFDI complement payment',
      INVOICE_PREMIUM_BATCH_MENU: 'Report Plus Web Payments',
      INVOICE_PAYMENT_BATCH_MENU: 'Report Plus Batch Payments',
      NEW_INVOICE: 'New Invoice',
      NEW_TAX_INVOICE: 'New Invoice with Taxes',
      NEW_BATCH: 'New Invoice Batch',
      NEW_STATIC_BATCH: 'New Manual Batch Invoice',
      GET_CFDI_RESPONSE: 'CFDI status',
      TITLE: 'Invoice',
      ADD_TITLE: 'Add Invoice',
      EDIT_TITLE: 'Edit Invoice',
      DELETE_TITLE: 'Delete Invoice',
      CANCEL_CONFIRMATION: 'Get CFDI Status',
      PAID_TITLE: 'Paid Invoice',
      MANAGE_TITLE: 'Administrator',
      DRAFT_TITLE: 'Draft Invoice',
      DOCUMENT_TYPE: 'Document type',
      DOCUMENT_TYPE_ERROR: 'Document type is required',
      FILE_TYPE: 'File type',
      FILE_TYPE_ERROR: 'File type is required',
      FILE: 'File',
      CHOSE_FILE: 'Please select your file to upload to the system',
      FILE_ERROR: 'File is required',
      FILE_TYPE_TEXT: 'File must be text/plain',
      FILE_TYPE_XML: 'File must be xml',
      STATUS: 'Status',
      SERIES_FOLIO: 'Series / Folio',
      EDIT_TAX_MESSAGE: 'Exento tax is not editable',
      RECEPTOR_RFC: 'RFC Receiver',
      RECEIVER_NAME: 'Receiver Name',
      INVOICE_PAYMENT_DATE: 'Payment date',
      INVOICE_PAID: 'Paid',
      COMPANY_RFC: 'Company RFC',
      TRADE_NAME: 'Trade Name',
      INVOICE_CREATION_DATE: 'Certificate/Stamp date',
      TYPE_OF_VOUCHER: 'Type of Voucher',
      CERTIFICATE: 'Certifier',
      INVOICE_CONFIRMATION_CODE: 'Confirmation code',
      INVOICE_RELATION_TYPE: 'Type of relationship',
      UUID_RELATED: 'UUID related',
      INVOICE_PAYMENT: 'Payment Method',
      INVOICE_PAYMENT_WAY: 'Payment Way',
      INVOICE_STATE: 'Status',
      GRID_PLACE: 'Expedition place',
      GRID_CANCELLED_DATE: 'Cancellation date',
      BY_USER: 'Created by user',
      PRODUCT_ID: 'Product Name',
      QUANTITY: 'Quantity',
      UNIT_OF_MEASUREMENT: 'Unit',
      UNIT_PRICE: 'Unit Price',
      IEPS_PERCENTAGE: 'Discount',
      DESCRIPTION: 'Description',
      ADD_PRODUCT: 'Add Concept',
      EDIT_PRODUCT: 'Edit Concept',
      ADD_INFORMATION: 'Add',
      NUMBER: 'Number',
      DATE: 'Date',
      ADUANA: 'Aduana',
      PRODUCT_CODE: 'Product Code',
      CUSTOM_VAT: 'Identification No',
      BRANCH_ADDRESS:
        'Will you use a different shipping address than your tax address?',
      DISCOUNT: 'Discount',
      REQUIREMENT_NUMBER: 'Requirement Number',
      INFORMATION_ADDITION: 'Additional Information (optional)',
      PROPERTY_ACCOUNT: 'Property Account',
      ADD_PROPERTY_ACCOUNT: 'Add Property Account',
      ADD_PART: 'Add Part',
      EDIT_TOTAL: 'Change the Value',
      TAXES: 'Taxes',
      DISCOUNT_HEADING: 'Add Discount (optional)',
      DESCUENTO: 'Discount',
      DISCOUNT_REASON: 'Discount Reason',
      TAX_MOVE: 'Taxes Move (optional)',
      RETENTION_IAV: 'VAT Discount (optional)',
      RETENTION_ISR: 'Retention ISR (optional)',
      WITHHOLDING_TAX: 'Withholding Tax (optional)',
      OTHER_TAX: 'Other Tax (optional)',
      TAX_CEDULAR_TRANSER: 'Taxes Transfers (optional)',
      PAY: 'I will pay',
      INCLUDE_NOTE: 'Include promissory note text on your invoice',
      ADDENDA_HEADING: 'Select Addenda',
      SUB_TOTAL: 'Sub Total',
      IEPS: 'IEPS',
      TOTAL_TAX_MOVE: 'Total transfer tax',
      TOTAL_TAX_WITHHOLD: 'Total retention tax',
      TOTAL: 'Total',
      PAYMENT_CONDITION: 'Payment Condition',
      CONFIRMATION_CODE: 'Confirmation Code',
      CFDI_RELATED: 'Cfdi Related',
      TYPE_OF_RELATIONSHIP: 'Type of relationship',
      RELATED_UUID: 'Related UUID',
      AMOUNT: 'Amount',
      ALL_PART_OF_RELEATED_PRODUCT:
        'Choose part number where you need to add this record',
      TRANSFER: 'Transfer Amount',
      RETENTION: 'Retention Amount',
      OTHER_TRANSFER: 'Other Transfer Amount',
      OTHER_RETENTION: 'Other Retention Amount',
      OTHER_TOTAL_TAX_MOVE: 'Total other transfer tax',
      OTHER_TOTAL_TAX_WITHHOLD: 'Total other retention tax',
      BASE: 'Base',
      INVOICE_TAX_TYPE: 'Tax',
      FACTOR_TYPE: 'Factor Type',
      SHARE_FEES: 'Share Fees',
      ADD_TAXES: 'Add Taxes',
      MODIFY_TAXES: 'Modify Taxes',
      ADD1: 'Add',
      RECEIVER_DATA: 'Customer Information',
      CUSTOMER: 'Customer Name',
      CUSTOMER_ON_FILTER: 'Customer',
      MAIN_DISCOUNT: 'Add Discount(optional)',
      ADD: 'Add',
      USE_FREE_TEXT: 'Free Text area',
      TAX_NAME1: 'Tax Name',
      PERCENTAGE: 'Percentage',
      PAYMENT_HEADING: 'General Payment Information',
      PAYMENT_AMOUNT: 'Payment Amount',
      OPERATION_NUMBER: 'Operation Number',
      RFC_ORIGIN_ENTITY: 'RFC Entity Origin',
      BANK_NAME: 'Bank Name',
      ORDER_ACCOUNT: 'Order Account',
      DEPOSITE_INFO: 'Deposite Information (Optional)',
      RFC_ENTITY_DESTINATION: 'RFC Destination Account',
      RFC_ENTITY_NAME: 'RFC Destination Account Name',
      RECEIVE_ACCOUNT: 'Account where the payment is received',
      PAYMENT_TYPE: 'Payment Type',
      PAYMENT_CERTIFICATE: 'Payment Certificate',
      ORIGINAL_PAYMENT: 'Original payment chain',
      DIGITAL_PAYMENT_STAMP: 'Digital Payment Stamp',
      ADD_PAYMENT: 'Add Payment',
      MODIFY_PAYMENT: 'Modify Payment',
      APP_PAYMENT_DETAIL: 'Add Related CFDI',
      MODIFY_PAYMENT_DETAIL: 'Modify Related CFDI',
      ADD_RELATED_DATA: 'Add Related CFDI',
      RALATED_CFDI_INFO: 'Related CFDI data',
      CFDI_TEXT: 'CFDI Tax',
      SERIE: 'CFDI series',
      FOLIO: 'CFDI Folio',
      CURRENCY: 'Currency',
      PARTIAL_NUMBER: 'Partial Number',
      PREVIOUS_AMOUNT: 'Previous Amount',
      PAID_AMOUNT: 'Paid Amount',
      UNPAID_AMOUNT: 'Unpaid Amount',
      PAYMENT_CUSTOMER:
        'To use this option it is necessary to add records from the same client',
      PAYMENT_COMPANY:
        'To use this option it is necessary to add records from the same branch or company',
      REQUIRED_FIELDS: 'Fill required fields',
      CER_KEY_REQUIRED_FIELDS: 'A certificate (cer) and key are required to proceed with the upload.',
      STUDENT_NAME: 'Student Name',
      CURP: 'CURP',
      LEVEL: 'Education level',
      AUTHORIZATION: 'Official Authorization',
      RFC_PAYMENT: 'Payment RFC',
      COMPLEMENT: 'Accessories',
      COMPLEMENT_CONCEPT: 'Complement Concept',
      USE_CFDI_COUNTER: 'Use same series and folio of CFDI section',
      SELECT_COMPLEMENT_TYPE: 'Choose One',
      PROCESS_TYPE: 'Process Type',
      COMMITTEE_TYPE: 'Committee Type',
      NATIONAL_ACCOUNTING_ID:
        'Accounting Code for ordinary process and National Executive Committee',
      INTERNATIONAL_ACCOUNTING_ID:
        'Accounting key for ordinary process and State Executive Committee',
      STATE: 'State Type',
      SCOPE: 'Scope',
      INE_COMPLEMENT: 'INE Complement',
      SCHOOL_COMPLEMENT: 'School Complement',
      AMOUNT_ACQUISITION: 'Amount Acquisition',
      ALIENATION_AMOUNT: 'Alienation Amount',
      VEHICLE_KEY: 'Vehicle Key',
      BRAND: 'Brand',
      TYPE: 'Type',
      MODEL: 'Model',
      ENGINE_NUMBER: 'Engine Number',
      SERIAL_NUMBER: 'Serial Number',
      VALUE: 'Value',
      CUSTOM_INFORMATION: 'Custom Information',
      CUSTOM_NUMBER: 'Number',
      CUSTOM_DATE: 'Date',
      CUSTOM_TEXT: 'Addenda',
      PERMIT_NUMBER: 'Construction Permit or Authorization Number',
      STREET: 'Street',
      EXTERIOR_NUMBER: 'Exterior No.',
      INTERIOR_NUMBER: 'Interior No.',
      COLONY: 'Colony',
      LOCATION: 'Location',
      REFERENCE: 'Reference',
      MUNICIPALITY: 'Municipality',
      POSTAL_CODE: 'Postal Code',
      PROPERTY_TYPE: 'Property Type',
      OPERATION_DETAILS: 'Operation Details',
      NOTARY_DATA: 'Notary Details',
      DEALER_DATA: 'Dealer Data',
      NOTARY_INSTRUMENT: 'Notary Instrument',
      NOTARY_DATE: 'Notarial Instrument date',
      OPERATION_AMOUNT: 'Operation Amount',
      NOTARY_CURP: 'Notary CURP',
      NOTARY_NUMBER: 'Notary Number',
      NOTARY_SUBTOTAL: 'Sub Total',
      NOTARY_IVA: 'IVA',
      FEDERAL_ENTITY: 'Federal Entity',
      NOMINATION: 'Nomination',
      IS_PROPERTY: 'Is it a co_property or a conjugal society?',
      DEALER_FIRST_NAME: 'First Name',
      DEALER_LAST_NAME: 'Last Name',
      DEALER_MOTHER_LAST_NAME: 'Mother\'s Last Name',
      DEALER_RFC: 'RFC',
      DEALER_CURP: 'CURP',
      DEALER_PERCENTAGE: 'Percentage',
      RECEPTOR_DATA: 'Receptor Data',
      IS_RECEPTOR:
        'Will the property be acquired by co_ownership or conjugal partnership?',
      REASON: 'Transfer Reason',
      OPERATION_TYPE: 'Operation Type',
      CLAIM_CODE: 'Claims code',
      ORIGIN_CERTIFICATE_CODE: 'Origin Certificate',
      CERTIFICATE_NUMBER: 'Certificate Number',
      EXPORT_NUMBER: 'Reliable Exporter Number',
      INCOTERM_CODE: 'Incoterm Key',
      SUB_DIVISION: 'Sub Division',
      OBSERVATION: 'Observations',
      USD_EXCHANGE_RATE: 'Exchange Rate',
      TOTAL_USD: 'Total USD',
      TRANSFER_REASON: 'Transfer Reason',
      ISSUER_CURP: 'issuer CURP',
      OWNER_REGISTER_TAX_ID: 'Owner registered Tax ID',
      RESIDENCE_TAX: 'Residence Tax',
      RECEIVER_REGISTER_TAX_ID: 'Receiver Registered ID',
      RECIPIENT_NAME: 'Recipient Name',
      IDENTIFICATION_NUMBER: 'Identification Number',
      TARIFF_FRACTION: 'Tariff Fraction',
      GOOD_QUANTITY: 'Goods Quantity',
      MEASUREMENT_UNIT: 'Measurement Unit',
      CUSTOMER_UNIT_PRICE: 'Unit Price',
      DOLLAR_VALUE: 'Value in Dollar',
      SUB_MODEL: 'Sub Model',
      OPERATION_INFO: 'Operation Data',
      OWNER_INFO: 'Owner',
      RECEIVER_INFO: 'Receiver Data',
      ADDRESS_INFO: 'Addressee',
      RECIPIENT_ADDRESS_INFO: 'Recipient Address',
      COMMODITY_INFO: 'Commodity',
      SPECIFIC_DESCRIPTION_INFO: 'Specific Description',
      ISSUER_DATA: 'Issuer data',
      DESTINATION_INFO: 'Addressee',
      SPECIFIC_DESCRIPTION: 'Specific Descriptions',
      TEX_REGISTRY_IDENTIFICATION_NUMBER:
        'Identification Tax Number or Registry',
      RECIPIENT_BUSINESS_NAME: 'Recipient Business Name',
      EXPORTED_IDENTIFICATION_NO: 'Exported Merchandise Identification code',
      EXPORTED: 'Export',
      TRAFFIC_FRACTION: 'Tariff Fraction',
      COMMERCIAL_GOOD_QUANTITY: 'Quantity',
      COMMERCIAL_MEASUREMENT_UNIT: 'Measurement Unit',
      COMMERCIAL_UNIT_VALUE: 'Unit value (USD)',
      COMMERCIAL_TOTAL: 'Total (USD)',
      ADD_MERCHANDISE: 'Add Merchandise',
      ADD_NOTARY_PROPERTY: 'Add property record',
      ADD_COMMERCIALPROPERTY: 'Add Property',
      ADD_COMMERCIAL_DESTINATION: 'Add Destination',
      UPDATE_COMMERCIAL_DESTINATION: 'Update Destination',
      ADD_DESCRIPTION: 'Add Description',
      MERCHANDISE_MARK: 'Mark of merchandise',
      MERCHANDISE_MODEL: 'Merchandise model',
      MERCHANDISE_SUB_MODEL: 'Merchandise Sub Model',
      MERCHANDISE_SERIAL: 'Merchandise Serial Number',
      REMARK: 'Cancellation Reason',
      PAYMENT_DATE: 'Payment date',
      BANK: 'Bank',
      MOVEMENT_NUMBER: 'Movement Number',
      PAID: 'Paid',
      SERVER_RESPONSE: 'Server Response',
      CFDI_MESSAGE:
        'Please press fill and add the related cfdi fields before pressing Add payment',
      PAYMENT_MESSAGE: 'Please press add Payment',
      SELECTED_MORE_CFDI: ' + Select more related CFDI',
      MORE_CFDI: 'Select more related CFDI',
      SHOW_BUTTON: 'Show',
      ADD_CFDI_BUTTON: 'Payment Complement',
      SHOW_HIDE_COLUMN: 'Show/Hide columns',
      CHOSE_ONE: 'Choose one',
      SELECT_RECORD: 'Please select records',
      AUTHORIZATION_NUMBER: 'Authorization Number',
      AUTHORIZATION_DATE: 'Authorization date',
      LEGEND: 'Legend',
      SAME_ADDRESS_FOR_RECIPIENT: 'Use the same address for Recipient',
      IS_NATIONAL_TERRITORY_LEAVE:
        'Do the merchandise or merchandise leave the national territory?',
      IS_GOOD_ENTER_OR_LEAVE_NATIONAL:
        'Do the merchandise or goods enter or leave the national territory?',
      ENTRY_ROOT: 'Route of entry or exit of goods or merchandise',
      DISTANCE: 'Total Traveled Distance',
      IS_LOCATION_ACTIVE: 'Location',
      STATION_ID: 'Type of station during the transfer of goods',
      TRAVELLED_DISTANCE:
        'Distance traveled from point of origin to destination',
      SOURCE_LABEL: 'Source',
      ORIGIN_ID: 'Origin Id',
      SENDER_RFC: 'Sender RFC',
      SENDER_NAME: 'Sender Name',
      REGISTERED_TAX_ID: 'Registered Tax Id',
      TAX_RESIDENT: 'Tax Resident',
      STATION_NUMBER: 'Station Number',
      STATION_LIST_ID: 'Station Name',
      PORT_TYPE: 'Prot Type',
      DEPARTURE_DATE_TIME: 'Departure date and time',
      RECEPTOR_ADDRESS: 'Receptor',
      RECEPTOR_ID: 'Destination Id',
      RECEPTOR_ORIGIN_ID: 'Origin / Destination Id',
      RECEPTOR_SENDER_NAME: 'Sender / Receptor Name',
      CARRY_RECEPTOR_RFC: 'Receptor RFC',
      CARRY_RECEPTOR_NAME: 'Receptor Name',
      RECEPTOR_REGISTERED_TAX_ID: 'Registered Tax Id',
      RECEPTOR_TAX_RESIDENT: 'Tax Resident',
      RECEPTOR_STATION_NUMBER: 'Station Number',
      RECEPTOR_STATION_LIST_ID: 'Station Name',
      RECEPTOR_PORT_TYPE: 'Port Type',
      ARRIVED_DATE_TIME: 'Arrived date Time',
      ADDRESS_LABEL: 'Address',
      RECEPTOR_STREET: 'Street',
      RECEPTOR_OUTDOOR: 'Outdoor Number',
      RECEPTOR_INTERIOR: 'Interior Number',
      RECEPTOR_COLONY: 'Colony',
      RECEPTOR_LOCATION: 'Location',
      RECEPTOR_REFERENCE: 'Reference',
      RECEPTOR_MUNICIPALITY: 'Municipality',
      RECEPTOR_STATE: 'Origin / Destination Name',
      RECEPTOR_POSTAL_CODE: 'Postal Code',
      COUNTRY_ID: 'Country',
      ADD_LOCATION: 'Add Location',
      UPDATE_LOCATION: 'Update Location',
      IS_GOOD: 'Goods',
      GOOD_TOTAL_GROSS_WEIGHT: 'Total Gross Weight',
      GOOD_MEASUREMENT_UNIT: 'Measurement Unit',
      GOOD_TOTAL_NET_WEIGHT: 'Total Net Weight',
      GOOD_TOTAL_GOOD_UNIT: 'Total no. of Goods',
      GOOD_APPRAISAL_FEE: 'Appraisal fee',
      GOOD: 'Good',
      GOOD_PRODUCT_KEY: 'Product Code',
      GOOD_STCC_PRODUCT_KEY: 'STCC Product Code',
      GOOD_DESCRIPTION: 'Description',
      GOOD_MOVED_QUANTITY: 'Good Moved Quantity',
      GOOD_MEASUREMENT_CODE: 'Measurement Code',
      GOOD_UNIT_OF_MEASUREMENT: 'Measurement Unit',
      GOOD_PACKAGING: 'Good Packaging dimension',
      IS_GOOD_HAZARDOUS_MATERIAL:
        'Are the goods or merchandise considered hazardous material?',
      GOOD_HAZARDOUS_MATERIAL_TYPE:
        'Key to the types of hazardous material being moved',
      GOOD_PACKAGING_TYPE:
        'Type of packaging required to transport the goods or merchandise',
      GOOD_PACKAGING_DESCRIPTION: 'Good Packaging Description',
      GOOD_PACKAGING_WEIGHT: 'Weight in Kilograms of the goods or merchandise',
      GOOD_PACKAGING_VALUE: 'Value of goods or merchandise',
      GOOD_CURRENCY: 'Currency',
      GOOD_PACKAGING_TRAFFIC: 'Tariff fraction of the goods or merchandise',
      GOOD_PACKAGING_UUID: 'UUID of the CFDI foreign trade complement',
      ADD_GOOD: 'Add Good',
      UPDATE_GOOD: 'Add Good',
      TRANSPORTED_GOOD: 'Add Transported Good',
      ADD_TRANSPORTED_GOOD: 'Add Transported Good',
      MOVED_QUANTITY: 'Moved Quantity',
      DESTINATION_ID: 'Destination Id',
      TRANSPORT_CODE: 'Transport Code',
      MERCHANDISE_DETAILS: 'Merchandise Detail',
      ADD_MERCHANDISE_DETAILS: 'Add Merchandise Detail',
      UPDATE_MERCHANDISE_DETAILS: 'Update Merchandise Detail',
      PACKAGING_UNIT: 'Packaging Unit',
      GROSS_WEIGHT: 'Gross Weight',
      NET_WEIGHT: 'Net Weight',
      DEFECT_WEIGHT: 'Defect Weight',
      PIECES: 'Pieces',
      AUTO_TRANSPORT: 'Auto Transport',
      PERMISSION_TYPE_ID: 'Type of permit provided by the SCT',
      SCT_PERMIT_NUMBER: 'Permit number provided by the SCT',
      INSURER_NAME: 'Insurer Name',
      INSURANCE_POLICY_NUMBER: 'Insurance Policy Number',
      VEHICLE_IDENTIFICATION: 'Vehicle Identification',
      FEDERAL_TRUCKING_TYPE_ID: 'Trucking nomenclature key',
      MOTOR_LICENCE_PLAT: 'Motor carrier license plate',
      MOTOR_YEAR: 'Year or model of the motor carrier',
      TRAILER: 'Trailer',
      TRANSPORT_TRAILER: 'Trailer CCP',
      TRAILER_TYPE_ID: 'Trailer or Sub Trailer Type',
      TRAILER_PLAT: 'Trailer Plate',
      ADD_TRAILER: 'Add trailer',
      MARITIME_TRANSPORT: 'Maritime Transport',
      MARITIME_PERMISSION_TYPE_ID: 'Type of permit provided by the SCT',
      MARITIME_PERMIT_NUMBER: 'Permit number provided by the SCT',
      MARITIME_INSURER_NAME:
        'Name of the insurer that covers the boat protection insurance',
      MARITIME_POLICY_NUMBER: 'Boat protection insurance policy number',
      BOAT_TYPE_ID: 'Boat Type',
      VESSEL_REGISTRATION: 'Vessel registration',
      VESSEL_IMO_NUMBER: 'IMO number of the vessel',
      BOAT_YEAR: 'Boat Year',
      BOAT_NAME: 'Boat Name',
      VESSEL_COUNTRY_ID: 'Nationality of the vessel',
      GROSS_TONNAGE_UNIT: 'Gross tonnage units',
      LOAD_TYPE_ID: 'Load Type',
      ITC_CERTIFICATE: 'Certificate number issued by ITC',
      LENGTH_OF_LENGTH: 'Length of length (defined in feet)',
      TIP_LENGTH: 'Strut length (defined in feet)',
      SLEEVE_LENGTH: 'Sleeve length (defined in feet)',
      DRAFT_LENGTH: 'Draft length (defined in feet)',
      SHIPPING_LINE: 'Name of the shipping line that manages the transfer',
      SHIPPING_AGENT: 'Shipping Agent',
      AGENT_NUMBER_TYPE_ID:
        'Consignee shipping agent authorization number catalog',
      TRAVEL_NUMBER: 'Travel number with which the transfer is identified',
      BILL_LADING_NUMBER: 'Bill of lading number',
      TEMPORARY_NAVIGATION_PERMIT: 'Temporary navigation permit',
      CONTAINER_HEADING: 'Container',
      CONTAINER_IDENTIFICATION_NUMBER: 'Container Identification Number',
      SEA_CONTAINER_ID: 'Sea Container Id',
      SEAL_NUMBER: 'Seal Number',
      IDCCP_LAND_TRANSPORT_DOCUMENT: 'IdCCP of the land motor transport document',
      LICENSE_LAND_TRANSPORT_DOCUMENT: 'Vehicle license plate of the land transport document',
      DATE_LAND_TRANSPORT_DOCUMENT: 'Certification date of the land motor transport document',
      ADD_CONTAINER: 'Add Container',
      EDIT_CONTAINER: 'Update Container',
      AIR_TRANSPORT: 'Air Transport',
      AIR_PERMISSION_TYPE_ID: 'Type of permit provided by the SCT',
      AIR_PERMISSION_NUMBER: 'Permit number provided by the SCT',
      AIRCRAFT_REGISTRATION: 'Aircraft Registration',
      AIR_ISSUER_NAME: 'Air Issuer Name',
      AIR_INSURANCE_POLICY: 'Aircraft protection insurance policy number',
      AIR_GOOD_TRANSFER:
        'Air waybill with which the goods or merchandise are transferred',
      AIR_CONTACT_PLACE: 'Place of contract',
      AIR_CARRIER_RFC: 'RFC of the carrier',
      AIR_TYPE_ID: 'Carrier Code',
      CARRIER_TAX_NUMBER: 'Carrier Tax ID Number',
      AIR_COUNTRY_ID: 'Tax residence of the carrier',
      CARRIER_NAME: 'Carrier Name',
      SHIPPER_RFC: 'Shipper RFC',
      SHIPPER_TAX_NUMBER: 'Shipper Tax Number',
      SHIPPER_COUNTRY_ID: 'Tax residence of the shipper',
      SHIPPER_NAME: 'Shipper Name',
      RAILWAY_TRANSPORT: 'Railway Transport',
      SERVICE_TYPE_ID: 'Service Type',
      RAILWAY_INSURER_NAME: 'Name of the insurer that covers rail risks',
      RAILWAY_POLICY_NUMBER: 'Insurance policy number',
      RAILWAY_TRANSPORT_RFC: 'RFC of the transport concessionaire or assignee',
      RIGHT_WAY: 'Rights of way',
      WAY_TYPE_ID: 'Right_of_way key paid by the carrier',
      RAILWAY_PAID_BY: 'Kilometers paid by the carrier via rail',
      ADD_RIGHT: 'Add right of way',
      CAR_HEADING: 'Car',
      CAR_TYPE_ID: 'Car Type',
      REGISTRATION: 'Registration',
      GUIDANCE_NUMBER: 'Guidance Number',
      NET_TON: 'Net tons contained in the container',
      ADD_CAR: 'Add Car',
      CONTAINER_TYPE_ID: 'Container Type',
      EMPTY_CONTAINER_WEIGHT: 'Empty Container Weight',
      GOOD_NET_WEIGHT: 'Net weight in kilograms of the goods or merchandise',
      TRANSPORT_HEADING: 'Transport',
      TRANSPORT_ID: 'Transport Type',
      OPERATOR_HEADING: 'Operators',
      OPERATOR_RFC: 'Operator RFC',
      OPERATOR_LICENCE: 'Operator Licence',
      OPERATOR_NAME: 'Operator Name',
      OPERATOR_TAX_NUMBER: 'Operator Tax Number',
      OPERATOR_COUNTRY_ID: 'Operator Country',
      OPERATOR_ADDRESS_HEADING: 'Operator Address',
      OPERATOR_ADDRESS_STREET: 'Street',
      OPERATOR_ADDRESS_EXTERIOR_NUMBER: 'Exterior Number',
      OPERATOR_ADDRESS_INTERIOR_NUMBER: 'Interior Number',
      OPERATOR_ADDRESS_COLONY: 'Colony',
      OPERATOR_ADDRESS_LOCATION: 'Location',
      OPERATOR_ADDRESS_REFERENCE: 'Reference',
      OPERATOR_ADDRESS_MUNICIPALITY: 'Municipality',
      OPERATOR_ADDRESS_STATE: 'State',
      OPERATOR_ADDRESS_POSTAL_CODE: 'Postal Code',
      OPERATOR_ADDRESS_ADD: 'Add Operator Address',
      OWNER_HEADING: 'Owner',
      OWNER_RFC: 'Owner RFC',
      OWNER_NAME: 'Owner Name',
      OWNER_TAX_NUMBER: 'Owner Tax Number',
      OWNER_COUNTRY_ID: 'Owner Country',
      OWNER_ADDRESS_HEADING: 'Owner Address',
      OWNER_ADDRESS_ADD: 'Add Owner Address',
      TENANT_HEADING: 'Tenant',
      TENANT_RFC: 'Tenant RFC',
      TENANT_NAME: 'Tenant Name',
      TENANT_TAX_NUMBER: 'Tenant Tax Number',
      TENANT_COUNTRY_ID: 'Tenant Country',
      TENANT_ADDRESS_HEADING: 'Tenant Address',
      TENANT_ADDRESS_ADD: 'Add Tenant Address',
      NOTIFY_HEADING: 'Notify',
      NOTIFY_RFC: 'Notify RFC',
      NOTIFY_NAME: 'Notify Name',
      NOTIFY_TAX_NUMBER: 'Notify Tax Number',
      NOTIFY_COUNTRY_ID: 'Notify Country',
      NOTIFY_ADDRESS_HEADING: 'Notify Address',
      NOTIFY_ADDRESS_ADD: 'Add Notify Address',
      GOOD_COUNTRY_ID: 'Country of origin or destination of the goods',
      LOCATION_TYPE: 'Location Type',
      LOCATION_LABEL_ID: 'Location Id',
      PADIMENT: 'Pediment',
      PADIMENT_ADD: 'Add Pediment',
      IDENTIFICATION_GUIDE_ADD: 'Add Identification Guides',
      IDENTIFICATION_GUIDE: 'Identification Guides',
      PACKAGE_GUIDE_NUMBER: 'Package Guide Number',
      PACKAGE_DESCRIPTION: 'Package Description',
      PACKAGE_WEIGHT: 'Package Weight (k.g)',
      INSURANCE_LABEL: 'Insurance',
      INSURANCE_RISK_COVER: 'Insurer Cover Civil Liability Risks',
      POLICY_RISK_COVER: 'Policy Cover Civil Liability Risks',
      INSURER_COVER_ENVIRONMENT_DAMAGE:
        'Insurer Covers Possible Damage to the Environment',
      POLICY_COVER_ENVIRONMENT_DAMAGE:
        'Policy Covers Possible Damage to the Environment',
      INSURER_COVER_CARGO_DAMAGE: 'Insurer Covers the Risks of the Cargo',
      POLICY_COVER_CARGO_DAMAGE: 'Policy Covers the Risks of Cargo',
      INSURANCE_PREMIUM_VALUE: 'Value of the insurance premium contracted',
      TRANSPORT_FIGURE_LABEL: 'Transport Figure',
      TRANSPORT_FIGURE_ADD: 'Add Transport Figure',
      TRANSPORT_FIGURE_EDIT: 'Edit Transport Figure',
      TRANSPORT_FIGURE_CATEGORY_ID: 'Transport Figure Category',
      TRANSPORT_FIGURE_RFC: 'Transport Figure RFC',
      TRANSPORT_FIGURE_LICENSE_NUMBER: 'License Number',
      TRANSPORT_FIGURE_NAME: 'Transport Figure Name',
      TRANSPORT_FIGURE_TAX_ID: 'Transport Figure Tax Id',
      TRANSPORT_FIGURE_COUNTRY_ID: 'Transportation Figure Residence',
      TRANSPORT_PART_ADD: 'Add Transport Type',
      TRANSPORT_PART_ID: 'Transport Type',
      TRANSPORT_FIGURE_ADDRESS_COUNTRY_ID: 'Country',
      RAIL_TRAFFIC_TYPE_ID: 'Traffic Type',
      UUID_SUBSTITUTE: 'UUID Substiture',
      ADD_UUID_SUBSTITUTE: 'Add UUID Substiture',
      MESSAGE: {
        ADD_SUCCESS: 'Successful process. Please wait for your receipts to be issued.',
        EDIT_SUCCESS: 'Company Edited Successfully',
        DRAFT_SUCCESS: 'Bill added to the draft correctly.'
      }
    },
    INVOICE_BATCH: {
      SERIE: 'Serie',
      ISSUER_RFC: 'Issuer RFC',
      COMPANY: 'Company',
      RECEPTOR_RFC: 'Receptor RFC',
      RECEPTOR_NAME: 'Receptor name',
      SUB_TOTAL: 'Sub total',
      DISCOUNT: 'Discount',
      TOTAL_TRANSFER: 'Total transfer',
      TOTAL_RETAINED: 'Total retained',
      PAYMENT_DATE: 'Payment date',
    },
    PAYROLL_PREVIOUS: {
      TITLE: 'Payroll 3.3',
      SENDER_INFORMATION: 'Datos Del Emisor',
      DRAFT_TITLE: 'Payroll Draft 3.3',
      CANCELLED_PAYROLL: 'Cancelled payroll 3.3',
      FAILED_PAYROLL: 'Failed payroll 3.3',
      PAYROLL_BATCH: 'Payroll batch 3.3',
      PAYROLL_BATCH_CANCELLED: 'Cancelled batch 3.3',
      PAYROLL_BATCH_FAILED: 'Failed batch 3.3',
      NEW_PAYROLL: 'New Payroll 3.3',
      ADD_TITLE: 'Add Payroll',
      EDIT_TITLE: 'Edit Payroll',
      ADD_TITLE33: 'Add Payroll 3.3',
      EDIT_TITLE33: 'Edit Payroll 3.3',
      MANAGE_TITLE: 'Manage',
      DELETE_TITLE: 'Delete Payroll',
      ADD_TITLE_RELATION: 'Add Payroll Batch with related CFDI',
    },
    PAYROLL_MULTIPLE: {
      TITLE: 'Payroll management 4.0',
      SENDER_INFORMATION: 'Datos Del Emisor',
      DRAFT_TITLE: 'Payroll Draft global',
      CANCELLED_PAYROLL: 'Cancelled payroll global',
      FAILED_PAYROLL: 'Failed payroll global',
      PAYROLL_BATCH: 'Payroll batch global',
      PAYROLL_BATCH_ADD: 'Add Payroll Batch 4.0',
      PAYROLL_BATCH_CANCELLED: 'Cancelled batch global',
      PAYROLL_BATCH_FAILED: 'Failed batch global',
      NEW_PAYROLL: 'New Payroll global',
      ADD_TITLE: 'Add Payroll multiple 4.0',
      EDIT_TITLE: 'Edit Payroll multiple 4.0',
      MANAGE_TITLE: 'Manage',
      DELETE_TITLE: 'Delete Payroll',
      ADD_TITLE_RELATION: 'Add Payroll Batch with related CFDI',
      MESSAGE: {
        ADD_SUCCESS:
          'Successful process. Please wait for your receipts to be issued.',
        EDIT_SUCCESS: 'Payroll Edited Successfully',
        DRAFT_SUCCESS: 'Nómina añadido al borrador correctamente.',
      },
    },
    PAYROLL: {
      TITLE: 'Payroll',
      SENDER_INFORMATION: 'Datos Del Emisor',
      DRAFT_TITLE: 'Payroll Draft 4.0',
      CANCELLED_PAYROLL: 'Cancelled payroll 4.0',
      FAILED_PAYROLL: 'Failed payroll 4.0',
      PAYROLL_BATCH: 'Payroll batch 4.0',
      PAYROLL_BATCH_ADD: 'Add Payroll Batch 4.0',
      PAYROLL_BATCH_CANCELLED: 'Cancelled batch 4.0',
      PAYROLL_BATCH_FAILED: 'Failed batch 4.0',
      ADD_TITLE: 'Add Payroll 4.0',
      EDIT_TITLE: 'Edit Payroll 4.0',
      MANAGE_TITLE: 'Manage',
      DELETE_TITLE: 'Delete Payroll',
      ADD_TITLE_RELATION: 'Add Payroll Batch with related CFDI',
      PAYROLL_TYPE: 'Payroll Type',
      FOLLIO_FISCAL: 'Fiscal Folio',
      INTERNAL_FOLIO: 'Internal Folio',
      ISSUE_DATE: 'Date of issue',
      EMPLOYEE_RFC: 'Employee RFC',
      EMPLOYEE_CURP: 'Employee CURP',
      EMPLOYEE_NAME: 'Employee Name',
      INITIAL_PAYMENT_DATE: 'Initial Payment date',
      FINAL_PAYMENT_DATE: 'Final Payment date',
      WORKED_DAYS: 'Worked Days',
      TOTAL_DISABILITY: 'Total Disabilities',
      TOTAL_TO_PAY: 'Total to Pay',
      CREATION_DATE: 'Created date',
      COMPANY_EMPLOYEE: 'Company/Employee',
      CFD_DATA: 'CFD Data',
      PAYMENT_SENDER_INFORMATION: 'Corporate Information',
      PERCEPTION: 'Perceptions',
      RECEIVER_DATA: 'Receiver Data',
      ADD_PERCEPTION: 'Add Perception',
      DEDUCTION: 'Deductions',
      DISABILITY: 'Disabilities',
      OTHER_PAYMENT: 'Other Payments',
      TOTAL: 'Totals',
      ADDITIONAL_INFORMATION: 'Additional Information',
      OBSERVATIONS: 'Observations',
      DELETE_CONFIRM_MESSAGE: 'Are you sure want to delete this record?',
      ADD_EMPLOYEE: 'Add Employee',
      COMPANY_TITLE: 'Add Company',
      DOCUMENT_TYPE: 'Document Type',
      TYPE_OF_CURRENCY: 'Type of Currency',
      EXCHANGE_RATE: 'Exchange Rate',
      BUSINESS: 'Business',
      PAYMENT_BUSINESS: 'Corporate Name',
      EMPLOYEE: 'Name of the Employee',
      RFC: 'RFC',
      DOCUMENT_TYPE_2: 'Proof of receipt',
      PAYMENT_DATE: 'Payment date',
      PAYMENT_START_DATE: 'Payment start date',
      PAYMENT_END_DATE: 'Payment end date',
      DAYS_PAID: 'No of Paid date',
      PERCEPTION_NAME: 'Name of perception',
      CLUSTERING_KEY: 'Clustering Key',
      PERCEPTION_KEY: 'Perception Key',
      DESCRIPTION: 'Description',
      SAVED_AMOUNT: 'Saved Amount',
      EXEMPT_AMOUNT: 'Exempt Amount',
      EXTRA_HOUR: 'Extra Hours',
      COMPENSATION_YEAR_OF_SERVICE: 'Service Rings',
      DAYS_WORK_PERFORM: 'Days in which the worker performs overtime',
      DAYS_PAYMENT_TYPE: 'Payment Type',
      NUMBER_OF_OVERTIME: 'Number of Overtime',
      AMOUNT_PAID: 'Paid Amount',
      ADD_EXTRA_HOUR: 'Add Extra Hours',
      DEDUCTION_NAME: 'Name of Deduction',
      PEYROLL_DEDUCTION_KEY: 'Deduction Key',
      AMOUNT: 'Amount',
      ADD_DEDUCTION: 'Add Deduction',
      DISABILITY_DAYS: 'Disability Days',
      TYPE_OF_DISABILITY: 'Type of Disability',
      DISABILITY_AMOUNT: 'Amount of Disability',
      WAY_TO_PAY: 'Way to Pay',
      PAYMENT_METHOD: 'Payment Method',
      EXPEDITION_PLACE: 'Expedition Place',
      NAME_OF_OTHER_PAYMENT: 'Name of other payment',
      GROUPING_KEY: 'Grouping Key',
      DEDUCTION_CODE: 'Deduction Code',
      CHANGE_VALUE: 'Change Values',
      ADD_ANOTHER_PAYMENT: 'Add another Payment',
      EMPLOYMENT_SUBSIDY: 'Employment Subsidy',
      SUBSIDY_CAUSE: 'Subsidy Caused',
      POSITIVE_BALANCE: 'Positive Balance',
      YEAR: 'Year',
      REMAINDER: 'Remainder',
      QUANTITY: 'Quantity',
      UNIT_OF_MEASUREMENT: 'Unit of Measurement',
      UNIT_VALUE: 'Unit Value',
      SUB_TOTAL: 'Sub Total',
      TOTAL_PERCEPTION: 'Total Perceptions',
      TOTAL_OTHER_PAYMENT: 'Total Other Payments',
      TOAL_ISR: 'Total ISR',
      RECEIPT_TYPE: 'Tipo de comprobante',
      PAYMENT_FOR: 'Payment for ',
      IF_HEAD: 'Separation and',
      EXTRA_HOUR_TYPE: 'Extra Hour Type',
      ADD_DISABILITY: 'Add Disability',
      TOTAL_DISCOUNT: 'Total discounts',
      TOTAL_DEDUCTION: 'Total Deductions',
      MODE: 'Mode',
      TOTAL_EXTRA: 'Total Extra Hour Amount',
      OPERATION: 'Operation',
      PAYMENT_FORM: 'Payment Form',
      TYPE: 'Type of payroll',
      PASSWORD: 'Password for document creation',
      PASSWORD_ERROR: 'Password is required',
      OTHER_YEAR: 'Other Year',
      OTHER_REMAINDER: 'Other Remainder',
      TOTAL_UNA_EXHIBITION: 'Total Paid',
      TOTAL_PAID: 'Total una Exbition',
      TOTAL_PARTIALITY: 'Years of Service',
      YEARS_OF_SERVICE: 'Total Partiality',
      DAILY_AMOUNT: 'Ultima',
      ULTIMA: 'Daily Amount',
      COMMUTATIVE_INCOME: 'Commutative Income',
      NON_COMMUTATIVE_INCOME: 'Non-cumulative Income',
      CFDI_USE_ID: 'Use CFDI',
      PRODUCT_CATALOG_ID: 'Product catalog',
      ADDENDA: 'Addenda',
      OTHER_CODE: 'Other payment code',
      MORE_CFDI: 'Related CFDI',
      PAYRLL_COMPLEMENT: 'Payroll complement',
      ADD_PAYRLL_COMPLEMENT: 'Add payroll complement',
      UPDATE_PAYRLL_COMPLEMENT: 'Update payroll complement',
      MESSAGE: {
        ADD_SUCCESS:
          'Successful process. Please wait for your receipts to be issued.',
        EDIT_SUCCESS: 'Retention Edited Successfully',
        CLONE_SUCCESS: 'Clone Created Successfully',
        DRAFT_SUCCESS: 'Retention added to the draft correctly',
      },
    },
    RETENTION_PREVIOUS: {
      RETENTION_MODULE: 'Retention Management 1.0',
      RETENTION: 'Retention 1.0',
      RETENTION_DRAFT: 'Retention Draft 1.0',
      CANCELLED_RETENTION: 'Cancelled Retention 1.0',
      FAILED_RETENTION: 'Failed Retention 1.0',
      RETENTION_BATCH_MODULE: 'Retention Batch 1.0',
      RETENTION_BATCH: 'Retention Batch 1.0',
      CANCELLED_RETENTION_BATCH: 'Cancelled Batch 1.0',
      FAILED_RETENTION_BATCH: 'Failed Batch 1.0',
    },
    RETENTION: {
      ADD_TITLE: 'Add Retention 2.0',
      EDIT_TITLE: 'Edit Retention 2.0',
      RETENTION_MODULE: 'Retention Management 2.0',
      RETENTION: 'Retention 2.0',
      RETENTION_DRAFT: 'Retention Draft 2.0',
      CANCELLED_RETENTION: 'Cancelled Retention 2.0',
      FAILED_RETENTION: 'Failed Retention 2.0',
      RETENTION_BATCH_MODULE: 'Retention Batch 2.0',
      RETENTION_BATCH: 'Retention Batch 2.0',
      RETENTION_BATCH_ADD: 'Add Batch Retention 2.0',
      CANCELLED_RETENTION_BATCH: 'Cancelled Batch 2.0',
      FAILED_RETENTION_BATCH: 'Failed Batch 2.0',
      RECEPTOR_RFC: 'Receiver RFC',
      RECEIVER_NAME: 'Receiver Name',
      TOTAL_RETENTION: 'Total Retention',
      RETENTION_KEY: 'Retention Key',
      DESCRIPTION: 'Description',
      COMPANY_NAME: 'Company Name',
      SOCIAL_NAME: 'Social Name',
      RECEPTOR_CURP: 'Receptor CURP',
      NATIONALITY: 'Nationality',
      RECEPTOR: 'Receiver',
      COMPANY: 'Issuer Data',
      PERIOD: 'Period',
      TOTAL_WITHHOLDING: 'Total',
      TAX_WITHHELD: 'Tax',
      ADD_WITHHOLDING: 'Add withholding tax',
      COMPLEMENTO: 'Accessories',
      INTERESES: 'Interests',
      DIVIDENDOS: 'Dividends',
      PREMIOS: 'Awards',
      PAGOS_A_EXTRANJEROS: 'Foreigners Payments',
      ADDITIONAL_INFORMATION: 'Additional Information',
      REMANTS: 'Remnants',
      INVESTMENT: 'Investment of shares',
      MORTGAGE_INTEREST: 'Mortgage interest',
      YES: 'Yes',
      NATIONAL: 'National',
      FOREIGN: 'Foreign',
      NATIONAL_SOCIETY: 'National society',
      FOREIGN_SOCIETY: 'Foreign society',
      CURP: 'Curp',
      RAZOR_SOCIAL: 'Business Name',
      IDENTIFICATION_NUMBER: 'Identification Number',
      TYPE: 'Type',
      ACTIVE: 'Active',
      RETENTION_KEY_ID: 'Retention Key',
      COMPANY_ID: 'Company',
      PASSWORD: 'Password',
      INITIAL_MONTH: 'Retention start Month',
      FINAL_MONTH: 'Retention end Month',
      FISCAL_EXERCISE: 'Fiscal exercise',
      OPERATION_AMOUNT: 'Total Transaction Amount',
      RECORDED_AMOUNT: 'Total recorded Amount',
      EXEMPT_AMOUNT: 'Total Exempt Amount',
      WITHHOLDING_AMOUNT: 'Total Withheld Amount',
      MONTHLY_UTILITY: 'Monthly Utility',
      CORRESPONDING_ISR: 'Corresponding ISR',
      WITHHOLDING_BASE_AMOUNT: 'Withholding Tax Amount',
      WITHHOLDING_TAX_AMOUNT: 'Withholding Tax Amount',
      RETENTION_TAX_TYPE_ID: 'Tax Withheld Type',
      RETENTION_DISTRIBUTED_INCOME_TYPE_ID: 'Payment withheld Type',
      IS_FINANCIAL_YEAR_WITH_SYSTEM:
        'The interest of the period were obtained in the financial year from the financial system?',
      IS_INTEREST_EARNED_IN_PERIOD:
        'The interest earned was withdrawn in the period or exercise?',
      IS_INTEREST_EARNED_TO_DERIVATIVE:
        'The interest earned corresponds to derivative financial transactions?',
      NORMAL_INTEREST_AMOUNT: 'Nominal Interest Amount',
      REAL_INTEREST_AMOUNT: 'Real Interest Amount',
      LOSS_INTEREST_AMOUNT: 'Interest Loss Amount',
      WITHHOLDING_DIVIDED_NATIONAL_AMOUNT:
        'Amount or withholding of the dividend or utility in national territory',
      WITHHOLDING_DIVIDED_FOREIGN_AMOUNT:
        'Amount or withholding of the dividend or profit in foreign territory',
      FOREIGN_HOLDING_AMOUNT: 'Foreign holding amount on dividends abroad',
      NATIONAL_CREDITABLE_INCOME_AMOUNT: 'Amount of National Creditable Income',
      DISTRIBUTED_BY_COMPANY:
        'Dividend distributed by national or foreign companies',
      NATIONAL_CUMULATIVE_DIVIDEND_AMOUNT:
        'Amount of the national cumulative dividend',
      FOREIGN_CUMULATIVE_DIVIDEND_AMOUNT: 'Foreign cumulative dividend amount',
      FEDERAL_ENTITY: 'Federal entity',
      PRIZE_AMOUNT: 'Amount of payment per prize',
      CHARGED_PRIZE_AMOUNT: 'Amount charged for prize money',
      EXEMPT_PRIZE_AMOUNT: 'Amount exempt by payment of prize',
      IS_PAYEE_RETAIN: 'Is the payee the same person who retains?',
      COUNTRY_ID: 'Country of residence key',
      PAYMENT_METHOD_ID: 'Payment Concept',
      RFC: 'RFC',
      CURP_LEGAL: 'Legal representative CURP',
      TAX_PAYEE_NAME: 'Taxpayer Name',
      RETENTION_FEDERAL_ENTITY_ID: 'Retention Federal Entity',
      PARTICIPANTS: 'Participants Proportion',
      INTERMEDIATION_CONTRACT: 'Intermediation Contract',
      GAIN: 'Gain',
      LOST: 'Lost',
      BANKING_INSTITUTE: 'The credit was granted by a banking institution',
      OUTSTANDING_BALANCE: 'Saldo Insoluto',
      DEDUCTIBLE_PROPORTION_OF_CREDIT:
        'Deductible proportion of the applicable credit on the actual accrued and paid interest',
      TOTAL_INTEREST: 'Total amount of nominal interest accrued',
      INTEREST_PAID: 'Total amount of nominal interest accrued and paid',
      CONTRACT_NUMBER: 'Mortgage loan contract number',
      NEW_WIDTHHOLDING_CERTIFICATE: 'New withholding certificate',
      DOCUMENT_DATE: 'Document date',
      ADD_RELATIONSHIP_CFDI: 'Add CFDI',
      ISSUE_CFDI: 'Issue CFDI',
      DRAFT: 'Draft',
      MESSAGE: {
        ADD_SUCCESS: 'Registro de Cliente creado correctamente',
        EDIT_SUCCESS: 'Registro de Cliente modificado correctamente',
      },
    },
    PAYROLL_BATCH_REPORT: {
      TITLE: 'Payroll batch report',
      PAYROLL_TYPE: 'Payroll type',
      ISSUER_RFC: 'Employee rfc',
      UUID: 'uuid',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      SUB_TOTAL: 'Subtotal',
      EMPLOYEE_CITIZEN_NUMBER_CURP: 'Employee CURP',
      EMPLOYEE_NUMBER: 'Employee number',
      EMPLOYEE_NAME: 'EMPLOYEE NAME',
      PAYMENT_METHOD: 'Payment Method',
      TAX_TYPE_TITLE: 'Tax Regime',
      POSTAL_CODE: 'Employee Postal Code',
      TAX_OBJECT: 'TAX OBJECT',
      PAYMENT_START_DATE: 'initial payment date',
      PAYMENT_END_DATE: 'final payment date',
      PAYMENT: 'payment date',
      DAYS_PAID: 'worked days',
      TOTAL_PERCEPTION: 'Total perceptions',
      DISCOUNT: 'total deductions',
      OTHER: 'total other payments',
      TOTAL_ISR: 'TOTAL ISR',
      TOTAL_EXTRAS: 'TOTAL EXTRA HOUR AMOUNT',
      TOTAL_DISABILITY: 'TOTAL DISABILITIES',
      TOTAL: 'total',
      PLACE_OF_EXPEDITION: 'expedition place',
      VERSION: 'Version',
      STATUS: 'status',
      AMOUNT_TAXED: 'saved amount',
      EXEMPT_AMOUNT: 'exempt amount',
    },
    PAYROLL_REPORT: {
      TITLE: 'Payroll report',
      PAYROLL_TYPE: 'Payroll type',
      ISSUER_RFC: 'Employee rfc',
      UUID: 'uuid',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      SUB_TOTAL: 'Subtotal',
      EMPLOYEE_CITIZEN_NUMBER_CURP: 'Employee CURP',
      EMPLOYEE_NUMBER: 'Employee number',
      EMPLOYEE_NAME: 'EMPLOYEE NAME',
      PAYMENT_METHOD: 'Payment Method',
      TAX_TYPE_TITLE: 'Tax Regime',
      POSTAL_CODE: 'Employee Postal Code',
      TAX_OBJECT: 'TAX OBJECT',
      PAYMENT_START_DATE: 'initial payment date',
      PAYMENT_END_DATE: 'final payment date',
      CANCELLED_DATE: 'Cancellation date',
      PAYMENT: 'payment date',
      DAYS_PAID: 'worked days',
      TOTAL_PERCEPTION: 'Total perceptions',
      DISCOUNT: 'total deductions',
      OTHER: 'total other payments',
      TOTAL_ISR: 'TOTAL ISR',
      TOTAL_EXTRAS: 'TOTAL EXTRA HOUR AMOUNT',
      TOTAL_DISABILITY: 'TOTAL DISABILITIES',
      TOTAL: 'total',
      PLACE_OF_EXPEDITION: 'expedition place',
      VERSION: 'Version',
      STATUS: 'status',
    },
    MULTIPLE_PAYROLL_BATCH_REPORT: {
      TITLE: 'Global payroll batch report',
      PAYROLL_TYPE: 'Payroll type',
      ISSUER_RFC: 'Employee rfc',
      UUID: 'uuid',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      EMPLOYEE_CITIZEN_NUMBER_CURP: 'Employee CURP',
      EMPLOYEE_NUMBER: 'Employee number',
      EMPLOYEE_NAME: 'EMPLOYEE NAME',
      PAYMENT_METHOD: 'Payment Method',
      TAX_TYPE_TITLE: 'Tax Regime',
      POSTAL_CODE: 'Employee Postal Code',
      TAX_OBJECT: 'TAX OBJECT',
      TOTAL_PERCEPTION: 'Total perceptions',
      DISCOUNT: 'total deductions',
      OTHER: 'total other payments',
      TOTAL_ISR: 'TOTAL ISR',
      TOTAL_EXTRAS: 'TOTAL EXTRA HOUR AMOUNT',
      TOTAL_DISABILITY: 'TOTAL DISABILITIES',
      TOTAL: 'total',
      PLACE_OF_EXPEDITION: 'expedition place',
      VERSION: 'Version',
      STATUS: 'status',
      SUB_TOTAL: 'Subtotal',
      AMOUNT_TAXED: 'saved amount',
      EXEMPT_AMOUNT: 'exempt amount',
    },
    MULTIPLE_PAYROLL_REPORT: {
      TITLE: 'Global payroll report',
      PAYROLL_TYPE: 'Payroll type',
      ISSUER_RFC: 'Employee rfc',
      UUID: 'uuid',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      EMPLOYEE_CITIZEN_NUMBER_CURP: 'Employee CURP',
      EMPLOYEE_NUMBER: 'Employee number',
      EMPLOYEE_NAME: 'EMPLOYEE NAME',
      PAYMENT_METHOD: 'Payment Method',
      TAX_TYPE_TITLE: 'Tax Regime',
      POSTAL_CODE: 'Employee Postal Code',
      TAX_OBJECT: 'TAX OBJECT',
      TOTAL_PERCEPTION: 'Total perceptions',
      DISCOUNT: 'total deductions',
      OTHER: 'total other payments',
      TOTAL_ISR: 'TOTAL ISR',
      TOTAL_EXTRAS: 'TOTAL EXTRA HOUR AMOUNT',
      TOTAL_DISABILITY: 'TOTAL DISABILITIES',
      TOTAL: 'total',
      PLACE_OF_EXPEDITION: 'expedition place',
      VERSION: 'Version',
      STATUS: 'status',
      SUB_TOTAL: 'Subtotal',
      AMOUNT_TAXED: 'saved amount',
      EXEMPT_AMOUNT: 'exempt amount',
    },
    RETENTION_BATCH_REPORT: {
      TITLE: 'Retention batch report',
      UUID: 'uuid',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      CODE: 'Retention Key',
      RAZOR_SOCIAL: 'social name',
      CURP: 'receptor curp',
      IDENTIFICATION_NUMBER: 'identification number',
      RECEPTOR_TYPE: 'nationality',
      OPERATION_AMOUNT: 'monthly utility',
      RECORDED_AMOUNT: 'total recorded amount',
      EXEMPT_AMOUNT: 'total exempt amount',
      WITHOLDING_AMOUNT: 'total witheld amount',
      TOTAL: 'toal transaction amount',
      CDFI: 'cdfi status response',
      RFC: 'RFC',
      STATUS: 'status',
      POSTAL_CODE: 'Receptor Postal Code',
      DESCRIPTION: 'description',
      BUSINESS_NAME: 'Business name',
      OBSERVATION: 'observations',
      VERSION: 'version',
    },
    RETENTION_REPORT: {
      TITLE: 'Retention report',
      UUID: 'uuid',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      CODE: 'Retention Key',
      RAZOR_SOCIAL: 'social name',
      CURP: 'receptor curp',
      IDENTIFICATION_NUMBER: 'identification number',
      RECEPTOR_TYPE: 'nationality',
      OPERATION_AMOUNT: 'monthly utility',
      RECORDED_AMOUNT: 'total recorded amount',
      EXEMPT_AMOUNT: 'total exempt amount',
      WITHOLDING_AMOUNT: 'total witheld amount',
      TOTAL: 'toal transaction amount',
      CDFI: 'cdfi status response',
      RFC: 'RFC',
      STATUS: 'status',
      POSTAL_CODE: 'Receptor Postal Code',
      DESCRIPTION: 'description',
      BUSINESS_NAME: 'Business name',
      OBSERVATION: 'observations',
      VERSION: 'version',
    },
    INVOICE_BATCH_REPORT: {
      TITLE: 'Invoice batch report',
      VERSION: 'version',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      CODE: 'type of currency',
      RFC: 'company RFC',
      STATUS: 'status',
      UUID: 'uuid',
      POSTAL_CODE: 'Receptor Postal Code',
      TOTAL: 'totals',
      BUSINESS_NAME: 'trade name',
      OBSERVATION: 'observations',
      EXPORT: 'Export',
      PAID: 'Paid',
      SUB_TOTAL: 'Subtotal',
      DISCOUNT: 'total deductions',
      YEAR: 'Year',
      NAME: 'receiver Name',
      SERIE: 'CFDI series',
      RECEIPT_TYPE: 'receipt type',
      CONFIRMATION_CODE: 'Confirmation Code',
      BOARD_TYPE: 'Board Type',
      CUSTOMER_RFC: 'RFC receiver',
      RELATED_UUID: 'Related UUID',
      PAYMENT_METHOD: 'Payment Method',
      PAYMENT_FORM: 'Payment way',
      PAYMENT_CONDITION: 'Payment Condition',
      TOTAL_TAX_MOVE: 'Total transfer tax',
      TOTAL_TAX_WITHHOLD: 'Total retention tax',
      PAYMENT_DATE: 'Payment date',
      BANK: 'Bank',
      MOVEMENT_NUMBER: 'Movement Number',
      INVOICE_STATUS: 'CFDI Status',
      USO_CFDI: 'uso cfdi',
      TAX_REGIME: 'tax regime',
      PERIODICITY: 'periodicity',
      MONTH: 'month',
      TYPE_OF_RELATION: 'type of relation',
      UUID_RELATED: 'uuid related',
      EXCHANGE_RATE: 'Exchange Rate',
      CANCELLATION_DATE: 'cancellation date',
      ADDENDA: 'Addenda',
      MASTER: 'created by user',
      CONCEPT: 'Concept',
    },
    INVOICE_REPORT: {
      CERTIFICATE: 'Certificate',
      TITLE: 'Invoice report',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      CODE: 'type of currency',
      RFC: 'company RFC',
      STATUS: 'status',
      UUID: 'uuid',
      POSTAL_CODE: 'Receptor Postal Code',
      TOTAL: 'totals',
      BUSINESS_NAME: 'trade name',
      OBSERVATION: 'observations',
      EXPORT: 'Export',
      PAID: 'Paid',
      SUB_TOTAL: 'Subtotal',
      DISCOUNT: 'total deductions',
      YEAR: 'Year',
      NAME: 'receiver Name',
      SERIE: 'CFDI series',
      RECEIPT_TYPE: 'receipt type',
      CONFIRMATION_CODE: 'Confirmation Code',
      BOARD_TYPE: 'Board Type',
      CUSTOMER_RFC: 'RFC receiver',
      RELATED_UUID: 'Related UUID',
      PAYMENT_METHOD: 'Payment Method',
      PAYMENT_FORM: 'Payment way',
      PAYMENT_CONDITION: 'Payment Condition',
      TOTAL_TAX_MOVE: 'Total transfer tax',
      TOTAL_TAX_WITHHOLD: 'Total retention tax',
      PAYMENT_DATE: 'Payment date',
      BANK: 'Bank',
      MOVEMENT_NUMBER: 'Movement Number',
      INVOICE_STATUS: 'CFDI Status',
      USO_CFDI: 'uso cfdi',
      TAX_REGIME: 'tax regime',
      PERIODICITY: 'periodicity',
      MONTH: 'month',
      TYPE_OF_RELATION: 'type of relation',
      UUID_RELATED: 'uuid related',
      EXCHANGE_RATE: 'Exchange Rate',
      CANCELLATION_DATE: 'cancellation date',
      ADDENDA: 'Addenda',
      MASTER: 'created by user',
      TAX_OBJECT_CATEGORY_ID: 'Tax object',
    },
    INVOICE_PAYMENT_BATCH_REPORT: {
      TITLE: 'Invoice payment batch report',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      CODE: 'type of currency',
      RFC: 'company RFC',
      STATUS: 'status',
      UUID: 'uuid',
      RECEIPT_TYPE: 'receipt type',
      VERSION: 'version',
      BUSINESS_NAME: 'Business Name',
      BOARD_TYPE: 'Board Type',
      CUSTOMER_RFC: 'rfc receiver',
      NAME: 'receiver\'s name',
      USE_CDFI: 'use cdfi',
      CANCELLATION_DATE: 'cancellation date',
      OBSERVATION: 'Observations',
      CONFIRMATION_CODE: 'Confirmation Code',
      TYPE_OF_RELATION: 'type of relation',
      RELATED_UUID: 'Related UUID',
      PAYMENT_METHOD: 'Payment Method',
      PAYMENT_FORM: 'Payment way',
      PAYMENT_CONDITION: 'Payment Condition',
      SUB_TOTAL: 'Subtotal',
      DISCOUNT: 'Discount',
      TOTAL: 'Total',
      BY_USER: 'Created by user',
      PAID: 'Paid',
      PAYMENT_DATE: 'Payment date',
      BANK: 'Bank',
      MOVEMENT_NUMBER: 'Movement Number',
      INVOICE_PAYMENT_DATE: 'date',
      PAYMENTFORM: 'payment form',
      PAYMENT_EXCHANGE_CURRENCY: 'payment exchange currency',
      EXCHANGE_RATE: 'Exchange Rate',
      PAYMENT_AMOUNT: 'Payment Amount',
      PAYMENT_RFC: 'RFC ENTITY ORIGIN',
      BANKNAME: 'bank name',
      RFC_DESTINATION_ACCOUNT_NAME: 'RFC Destination Account Name',
      PAYMENT_UUID: 'CFDI TAX',
      PAYMENT_SERIES: 'CDFI SERIES',
      PAYMENT_FOLIO: 'PAYMENT FOLIO',
      PAYMENT_PARTIAL_NUMBER: 'PARTIAL NUMBER',
      PreviousAmount: 'Previous Amount',
      PaidAmount: 'Paid Amount',
      UnpaidAmount: 'Unpaid Amount',
      PREVIOUS_AMOUNT: 'Previous Amount',
      PAID_AMOUNT: 'Paid Amount',
      UNPAID_AMOUNT: 'Unpaid Amount',
      CDFI_STATUS: 'CDFI STATUS RESPONSE',
    },
    INVOICE_PAYMENT_REPORT: {
      TITLE: 'Invoice payment report',
      WEB_SERVICE: 'certifier',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      CODE: 'type of currency',
      RFC: 'company RFC',
      STATUS: 'status',
      UUID: 'uuid',
      RECEIPT_TYPE: 'receipt type',
      VERSION: 'version',
      BUSINESS_NAME: 'Business Name',
      BOARD_TYPE: 'Board Type',
      CUSTOMER_RFC: 'rfc receiver',
      NAME: 'receiver\'s name',
      USE_CDFI: 'use cdfi',
      CANCELLATION_DATE: 'cancellation date',
      OBSERVATION: 'Observations',
      CONFIRMATION_CODE: 'Confirmation Code',
      TYPE_OF_RELATION: 'type of relation',
      RELATED_UUID: 'Related UUID',
      PAYMENT_METHOD: 'Payment Method',
      PAYMENT_FORM: 'Payment way',
      PAYMENT_CONDITION: 'Payment Condition',
      SUB_TOTAL: 'Subtotal',
      DISCOUNT: 'Discount',
      TOTAL: 'Total',
      BY_USER: 'Created by user',
      PAID: 'Paid',
      PAYMENT_DATE: 'Payment date',
      BANK: 'Bank',
      MOVEMENT_NUMBER: 'Movement Number',
      INVOICE_PAYMENT_DATE: 'date',
      PAYMENTFORM: 'payment form',
      PAYMENT_EXCHANGE_CURRENCY: 'payment exchange currency',
      EXCHANGE_RATE: 'Exchange Rate',
      PAYMENT_AMOUNT: 'Payment Amount',
      PAYMENT_RFC: 'RFC ENTITY ORIGIN',
      BANKNAME: 'bank name',
      RFC_DESTINATION_ACCOUNT_NAME: 'RFC Destination Account Name',
      PAYMENT_UUID: 'CFDI TAX',
      PAYMENT_SERIES: 'CDFI SERIES',
      PAYMENT_FOLIO: 'PAYMENT FOLIO',
      PAYMENT_PARTIAL_NUMBER: 'PARTIAL NUMBER',
      PreviousAmount: 'Previous Amount',
      PaidAmount: 'Paid Amount',
      UnpaidAmount: 'Unpaid Amount',
      CDFI_STATUS: 'CDFI STATUS RESPONSE',
    },
    INVOICE_PREMIUM_BATCH_REPORT: {
      TITLE: 'Invoice premium batch report',
      statusColor: 'Payment Status',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      UUID: 'uuid',
      TAX_REGIME: 'tax regime',
      CUSTOMER_RFC: 'rfc receiver',
      NAME: 'receiver\'s name',
      USE_CDFI: 'use cdfi',
      POSTALCODE: 'Postal Code',
      STATUS: 'status',
    },
    INVOICE_PREMIUM_REPORT: {
      TITLE: 'Invoice premium report',
      statusColor: 'Payment Status',
      FOLIO: 'Internal Folio',
      DATE: 'Created date',
      UUID: 'uuid',
      TAX_REGIME: 'tax regime',
      CUSTOMER_RFC: 'rfc receiver',
      NAME: 'receiver\'s name',
      USE_CDFI: 'use cdfi',
      POSTALCODE: 'Postal Code',
      STATUS: 'status',
      SEE_DETAILS: 'See details',
      SEE_INVOICE_DETAILS: 'See invoice details',
    },
    REPORT: {
      DOWNLOAD_INVOICE_TITLE: 'Download invoice',
      DOWNLOAD_INVOICE_BATCH_TITLE: 'Download invoice batch',
      DOWNLOAD_PAYROLL_TITLE: 'Download payroll',
      DOWNLOAD_PAYROLL_BATCH_TITLE: 'Download payroll batch',
      DOWNLOAD_RETENTION_TITLE: 'Download retention',
      DOWNLOAD_RETENTION_BATCH_TITLE: 'Download retention batch',
      FILTER_TITLE: 'Filter by',
      TYPE: 'Type',
      START_DATE: 'Start date',
      END_DATE: 'End date',
      KEYWORD: 'Keyword',
      FOLIO_START: 'Folio min',
      FOLIO_END: 'Folio max',
      COMPANY: 'Company',
      CUSTOMER: 'Customer',
      DOCUMENT_SUCCESS: 'CFDIS success',
      DOCUMENT_CANCELLED: 'CFDIS canceled',
      VERSION_1: 'Version 1.0',
      VERSION_2: 'Version 2.0',
      VERSION_3: 'Version 3.3',
      VERSION_4: 'Version 4.0',
      DOWNLOAD_XML: 'Download XML',
      DOWNLOAD_ASSIGN_XML: 'Download stamped XML',
      DOWNLOAD_PDF: 'Download PDF',
      DOWNLOAD_XML_PDF: 'Download XML/PDF',
      DOWNLOAD_SELECTED_CANCEL_PDF: 'Download Cancellation Ack',
      SEND_XML_PDF_TO_MAIL: 'Send an email',
      CANCEL_SERVICE: 'Cancel CFDI ',
      DOWNLOAD_HEAD: 'Alert',
      DOWNLOAD_TOOLTIP:
        'Please check the box for the required records and then press one of the options to download xml, pdf, both documents or cancel CFDI\'s.',
      ADD_RECORD: 'Add record',
      PREVIOUS: 'Previous',
      NEXT: 'Next',
      NO_RECORD: 'No record found!',
      SHOW_RECORD: 'Show',
      NUM_CFDI: 'Available CFDI',
      NUM_API: 'Available API/BATCH',
      CONFIRM: 'Confirm',
      FOLIO_SERIES: 'Series/Folio',
      PAYMENT_DETAILS: 'Payment details',
      PAYMENT_HEADING: '',
      INVOICE_DETAILS: 'Invoice details',
      ERROR: {
        TYPE: 'Type is required',
        START_DATE: 'Start date is required',
        END_DATE: 'End date is required',
        DOCUMENT: 'Document type is required',
        VERSION: 'Version  is required',
        ROW_ERROR: 'Please select rows',
        OK: 'Ok',
      },
      DETAILS_FIELD: {
        RFC: 'FRC',
        RECEPTOR: 'Receiving social reason',
        UUID: 'UUID of each generated CFDI',
        SERIE_FOLIO: 'Serie/Folio',
        SYMBOL: 'Symbology to indicate if add or subtract (+) (-)',
        PAYMENT_AMOUNT: 'Amount of origin / Payments',
        AMOUNT: 'Balance',
        BANK: 'Bank',
        ACCOUNT: 'Bill',
        FETCH: 'Date of issue',
        RECEPTOR_RFC: 'RFC of the receiver',
        RECEPTOR_NAME: 'Receiver name',
        PAYMENT_STATUS: 'Payment Status',
        AMOUNT_ON_REPORT: 'Amount exceeded',
      },
    },
    OPERATOR: {
      TITLE: 'Operator Users',
      ADD_TITLE: 'Add Operator',
      EDIT_TITLE: 'Edit Operator',
      MANAGE_TITLE: 'Manage Operator Users',
      FIELDS: {
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        MOTHER_LAST_NAME: 'Mother Last Name',
        EMAIL_ADDRESS: 'Email Address',
        ACCESS_TYPE: 'Access Control',
        PASSWORD: 'Password',
        USERNAME: 'Username',
      },
    },
    ERROR: {
      REQUIRED: 'This field is required',
      TAX_REQUIRED: 'Please fill out all tax fields',
      AMOUNT_REQUIRED: 'The amount must be greater than 0',
      INVALID_NUMBER: 'Invalid number',
      USER_REQUIRED: 'Username is required and must be 8 characters',
      USER_SPACE_NOT_ALLOWED: 'Spaces are not allowed',
      USER_ALREADY_TAKEN: 'Username is already taken',
      USER_SUCCESS_MESSAGE: 'User updated successfully',
      SEND_USER_CREDENTIAL_MESSAGE: 'Please check your inbox.',
      CHECK_ALL_REQUIRED_FIELDS: 'Please check all required fields.',
    },
    CUSTOMER: {
      TITLE: 'Customer',
      CUSTOMER_ADDRESS: 'Customer Address',
      ADD_TITLE: 'Add Customer',
      ADD_MORE: 'Add More',
      EDIT_TITLE: 'Edit Customer',
      MANAGE_TITLE: 'Manage',
      NEW: 'New',
      FIELDS: {
        RFC: 'Rfc',
        NAME: 'Name',
        INTERIOR_NUMBER: 'Interior Number',
        REGISTERED_TAX_ID: 'Registered Tax Id',
        CFDI_USE: 'Use CFDI',
        STREET: 'Street Address',
        COMPANY_ID: 'Company',
        EXTERIOR_NUMBER: 'Exterior Number',
        COLONY: 'Colony',
        LOCATION: 'Location',
        ADDRESS_REFERENCE: 'Address',
        DELEGATION: 'Delegation or Municipality',
        COUNTRY: 'Country',
        STATE: 'State',
        OTHER_STATE: 'Other State',
        POSTAL_CODE: 'Postal Code',
        TELEPHONE: 'Telephone',
        EMAIL_ADDRESS: 'Email Address',
        WEBSITE: 'Website',
        USE_BRANCH: 'Office Branch Address',
        BRANCH_LABEL: 'Customer Brach Address',
        ACTIVE: 'Active',
        AUTOMATIC_MAIL: 'Send Mail',
        HTML_TEMPLATE: 'Html Template',
        ENABLED: 'Enabled',
        ACTIVE_FOR_ALL_COMPANIES: 'Use Customer in all companies',
        IS_PDF_IN_ENGLISH: 'Generate English PDF for this recipient',
        BOARD_TYPE: 'Tax Regime',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Customer Added Successfully',
        EDIT_SUCCESS: 'Customer Edited Successfully',
      },
    },
    PRODUCT: {
      TITLE: 'Product',
      ADD_TITLE: 'Add Product',
      EDIT_TITLE: 'Edit Product',
      MANAGE_TITLE: 'Manage',
      NEW: 'New',
      FIELDS: {
        INTERNAL_NAME: 'Name',
        PRODUCT_CATALOG_ID: 'Catalog Service',
        IDENTIFICATION_NUMBER: 'Identification Number',
        MEASUREMENT: 'Measurement Code',
        COMPANY_ID: 'Company',
        PRODUCT_CODE: 'Product Code',
        MEASURE: 'Measurement Code',
        UNIT_OF_MEASURE: 'Measurement Unit',
        DESCRIPTION: 'Description',
        ACTIVE: 'Active',
        UNIT_PRICE: 'Unit Price',
        CURRENCY_ID: 'Currency',
        IEPS_ACTIVE: 'Activate IEPS',
        IEPS_PERCENTAGE: 'IEPS Percentage',
        CUSTOM_VAT: 'Custom VAT',
        ENABLED: 'Enabled',
        TRANSFER_IVA: 'Tax (IVA)',
        TRANSFER_IEPS: 'Tax (IPES)',
        RETENTION_IVA: 'Tax (Retention IVA)',
        RETENTION_IEPS: 'Tax (Retention IPES)',
        RETENTION_ISR: 'Tax (Retention ISR)',
        ACTIVE_TAX: 'Activate exempt tax rate',
        TAX_OBJECT_CATEGORY: 'Tax Object',
        PERCENTAGE: 'Enter the percentage of ieps',
        PERCENTAGE_REQUIRED: 'Percentage is required',
        PERCENTAGE_NUMBER_CHECK: 'Percentage must be number',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Product Added Successfully',
        EDIT_SUCCESS: 'Product Edited Successfully',
      },
    },
    PART: {
      TITLE: 'Part',
      ADD_TITLE: 'Add Part',
      EDIT_TITLE: 'Edit Part',
      MANAGE_TITLE: 'Manage',
      NEW: 'New',
      FIELDS: {
        INTERNAL_NAME: 'Part Name',
        PRODUCT_CATALOG_ID: 'Product catalog service',
        IDENTIFICATION_NUMBER: 'Identification Number',
        MEASUREMENT: 'Measurement Code',
        COMPANY_ID: 'Company',
        PRODUCT_CODE: 'Product Code',
        UNIT_OF_MEASURE: 'Measure Unit',
        DESCRIPTION: 'Description',
        ACTIVE: 'Active',
        UNIT_PRICE: 'Unit Price',
        CURRENCY_ID: 'Currency',
        IEPS_ACTIVE: 'Activate IEPS',
        IEPS_PERCENTAGE: 'IEPS Percentage',
        CUSTOM_VAT: 'Custom VAT',
        ENABLED: 'Enabled',
        QUANTITY: 'Quantity',
        IVA_PERCENTAGE: 'IVA Percentage',
        RETENTION_PERCENTAGE: 'Retention Percentage',
        MEASUREMENT_TITLE: 'Measurement',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Part Added Successfully',
        EDIT_SUCCESS: 'Part Edited Successfully',
      },
    },
    TAX: {
      TITLE: 'Manage Tax',
      ADD_TITLE: 'Add Tax',
      EDIT_TITLE: 'Edit Tax',
      MANAGE_TITLE: 'Manage',
      NEW: 'New',
      FIELDS: {
        TAX_NAME: 'Tax name',
        PERCENTAGE: 'Percentage',
        BILLING_METHOD: 'Billing Method',
        TAX_TYPE: 'Tax type',
        CODE: 'Tax',
        TYPE: 'Type of tax',
        FEDERAL: 'Federal tax',
        OTHER: 'Other tax',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Tax Added Successfully',
        EDIT_SUCCESS: 'Tax Edited Successfully',
      },
    },
    ADDENDA: {
      TITLE: 'Addenda',
      ADD_TITLE: 'Add Addenda',
      EDIT_TITLE: 'Edit Addenda',
      DELETE_TITLE: 'Delete Addenda',
      FIELDS: {
        ATTRIBUTE: 'Title',
        TITLE: 'Title',
        VALUE: 'Content',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Addenda Added Successfully',
        EDIT_SUCCESS: 'Addenda Edited Successfully',
      },
    },
    EMPLOYEE: {
      TITLE: 'Employee',
      ADD_TITLE: 'Add Employee',
      EDIT_TITLE: 'Edit Employee',
      MANAGE_TITLE: 'Manage',
      NEW: 'New',
      FIELDS: {
        NAME: 'Name',
        EMAIL: 'Email Address',
        RFC: 'Rfc',
        MASTER: 'Master ID',
        JOB_TYPE: 'Job type',
        OFFICIAL_JOB_TYPE_ID: 'Scheme Type',
        EMPLOYEE_COMPANY_NUMBER: 'Company Number',
        EMPLOYEE_NUMBER: 'Employee Number',
        EMPLOYEE_CITIZEN_NUMBER_CURP: 'Employee Citizen CURP Number',
        SOCIAL_SECURITY_NUMBER: 'Social security Number',
        BANK_OF_EMPLOYEE: 'Bank',
        EMPLOYEE_JOB_START_DATE: 'Job start date',
        JOB_POSITION: 'Position',
        DEPARTMENT: 'Department',
        CONTRACT_TYPE: 'Contract type',
        PAYMENT_PERIOD_TYPE: 'Payment period type',
        JOB_RISK_TYPE: 'Risk type',
        ACTIVE: 'Active',
        DAILY_WAGE: 'Daily Wage',
        TOTAL_WAGE: 'Total Wage',
        AUTHCODE: 'Auth Code',
        POSTAL_CODE: 'Employee Postal Code',
        CONTA_CONNECT: 'Connect',
        AUTOMATIC_SEND: 'Automatic send',
        OUTSOURCING: 'Outsourcing',
        ADD_SUBCONTRACTOR: 'Add subcontractor',
        PERCENTAGE_TIME: 'Time Percentage',
        ADDITIONAL_EMPLOYER_DATA: 'Employer Additional Data',
        EMPLOYER_REGISTER: 'Employer register',
        RFC_ORIGIN_PATTERN: 'RFC Origin of the Pattern',
        SNCF: 'Emtidad SNCF',
        RESOURCE_ORIGIN: 'Resource Origin',
        RESOURCE_AMOUNT: 'Own Resource Amount',
        ACTION: 'Actions or Titles',
        MARKET_VALUE: 'Market value',
        GRANTED_PRICE: 'Granted Price',
        COMPANY_ID: 'Company',
        ANTIQUITY: 'Antiquity',
        SYNDICATED: 'Syndicated',
        SCHEMA_TYPE: 'Scheme Type',
        BANK_ACCOUNT: 'Bank account',
        STATE: 'State',
        SELECT: 'Select option',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Employee Added Successfully',
        EDIT_SUCCESS: 'Employee Edited Successfully',
      },
    },
    DEPARTMENT: {
      TITLE: 'Department',
      ADD_TITLE: 'Add department',
      EDIT_TITLE: 'Edit department',
      MANAGE_TITLE: 'Department',
      NEW: 'New',
      FIELDS: {
        NAME: 'Name',
        MASTER: 'Master ID',
        COST_CENTER: 'Cost Center',
        ACTIVE: 'Active',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Department Added Successfully',
        EDIT_SUCCESS: 'Department Edited Successfully',
      },
    },
    EMPLOYEE_OTHER: {
      TITLE: 'Employee Others',
      ADD_TITLE: 'Add Employee Others',
      EDIT_TITLE: 'Edit Employee Others',
      MANAGE_TITLE: 'Manage',
      NEW: 'New',
      FIELDS: {
        NAME: 'Name',
        INTERNAL_NAME: 'Internal Name',
        DEDUCTION_KEY: 'Deduction Key',
        EMPLOYEE_OTHER_TYPE_ID: 'Employee Other Type',
        DESCRIPTION: 'Description',
        AMOUNT: 'Amount',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Employee Others Added Successfully',
        EDIT_SUCCESS: 'Employee Others Edited Successfully',
      },
    },
    EMPLOYEE_DISCOUNT: {
      TITLE: 'Employee Discount',
      ADD_TITLE: 'Add Employee Discount',
      EDIT_TITLE: 'Edit Employee Discount',
      MANAGE_TITLE: 'Manage',
      NEW: 'New',
      FIELDS: {
        NAME: 'Name',
        DISCOUNT_DESCRIPTION: 'Description',
        DISCOUNT_CODE: 'Discount Code',
        AMOUNT: 'Amount',
        AMOUNT_EXEMPTED: 'Employee Exempted Amount',
        EMPLOYEE_DISCOUNT_TYPE_ID: 'Employee Discount Type',
        ACTIVE: 'Active',
        ISR: 'ISR',
        ENABLED: 'Enabled',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Employee Discount Added Successfully',
        EDIT_SUCCESS: 'Employee Discount Edited Successfully',
      },
    },
    EMPLOYEE_EXPENSE: {
      TITLE: 'Employee Expense',
      ADD_TITLE: 'Add Employee Expense',
      EDIT_TITLE: 'Edit Employee Expense',
      MANAGE_TITLE: 'Manage',
      NEW: 'New',
      FIELDS: {
        NAME: 'Name',
        DESCRIPTION: 'Description',
        PAYMENT_CODE: 'Payment Code',
        AMOUNT: 'Amount',
        EMPLOYEE_EXEMPTED: 'Employee Exempted Amount',
        EMPLOYEE_EXPENSE_TYPE_ID: 'Employee Expense Type',
        ACTIVE: 'Active',
        ENABLED: 'Enabled',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Employee Expense Added Successfully',
        EDIT_SUCCESS: 'Employee Expense Edited Successfully',
      },
    },
    RETENTION_RECEPTOR: {
      TITLE: 'Retention Receptor',
      ADD_TITLE: 'Add Retention Receptor',
      EDIT_TITLE: 'Edit Retention Receptor',
      DELETE_TITLE: 'Delete Retention Receptor',
      FIELDS: {
        POSTAL_CODE: 'Receptor postal code',
        NATIONAL: 'National',
        FOREIGN: 'Foreign',
        IDENTIFICATION_NUMBER: 'Identification number',
      },
      MESSAGE: {
        ADD_SUCCESS: 'Receptor Added Successfully',
        EDIT_SUCCESS: 'Receptor Edited Successfully',
      },
    },
    CERTIFICATE: {
      TITLE: 'Certificate Update',
      ADD_TITLE: 'Thread Tools',
      MANAGE_TITLE: 'Certificate',
      NEW: 'New',
      FIELDS: {
        CER_KEY: 'Load CER and KEY',
        CHANGE_CFDI_PASSWORD: 'Change the CFDI password',
        PREVIOUS_PASSWORD_FOR_CREATING_INVOICE: 'Invoice Previous Password',
        NEW_PASSWORD_FOR_CREATING_INVOICE: 'Invoice New Password',
        PRIVATE_KEY_PASS: 'Private key password for CER and KEY files',
        CLEAR: 'Clear',
        CER: 'CER',
        KEY: 'KEY',
        SECRET_QUESTION: 'Secret Question',
        SECRET_ANSWER: 'Secret Answer',
        EXPIRY_DATE: 'Expiry Date',
        RECOGNIZE: 'I accept, acknowledge and authorize Authorized Certification Suppliers or Billing Assistants used by Factura360, through which I comply with the obligation described in regulation I.2.7.2.1, published in the Official Gazette of the Federation on December 28, 2011 in the Miscellaneous Fiscal Resolution for 2014. ',
        IMPORTANT: 'Important',
        IMPORTANT_TOP_MESSAGE: 'A) This password is used each time you issue or cancel a tax document. Please save it and memorize it. In case you forget it you will have to reload your certificates.',
        IMPORTANT_MIDDLE_MESSAGE: 'Here is a mechanism for remembering your invoice password, which will be useful in case you do not remember it or when the system detects that your password is not correct.',
        IMPORTANT_BOTTOM_MESSAGE: 'Choose one of the following questions and write the answer. We suggest that the answer help you remember the password you just entered.',
        PASSWORD: 'Password'
      },
      MESSAGE: {
        ADD_SUCCESS: 'Certification Added Successfully',
        EDIT_SUCCESS: 'Certification Added Successfully',
        INVALID_CERTIFICATE: 'Invalid Certificate Password',
        PAYROLL_ERROR1: 'Crossed company payroll series limit.',
        PAYROLL_ERROR2: 'Crossed Master\'s total Payroll.',
        INVOICE_ERROR2: 'Your account has no documents available, Please purchase a new folio package',
        PAYROLL_ERROR3: 'Service Error',
        PAYROLL_ERROR4: 'Master has blocked.',
        PAYROLL_ERROR6: 'Invalid certificate password.'
      }
    },
  },
};
