export const locale = {
  lang: 'en',
  data: {
    MENU: {
      DASHBOARD: {
        COLLAPSIBLE: 'Dashboards',
        ANALYTICS: 'Analytics',
        ECOMMERCE: 'eCommerce'
      },
      SETTING: {
        SECTION: 'Configuration',
        CURRENCY: {
          SECTION: 'Type of currencies'
        },
        WEBSERVICE: {
          SECTION: 'Web Services'
        },
        TAXSERVICE: {
          SECTION: 'Taxes'
        },
        PLAN: {
          SECTION: 'Plans'
        },
        BANK: {
          SECTION: 'Banks'
        },
        COUNTRY: {
          SECTION: 'Countries'
        },
        STATE: {
          SECTION: 'States'
        },
        LOCATION: {
          SECTION: 'Locations'
        },
        MUNICIPAL: {
          SECTION: 'Municipalities'
        },
        POSTCODE: {
          SECTION: 'Postal codes'
        },
        COLONY: {
          SECTION: 'Colonies'
        },
        TAX_OBJECT_CATEGORY: {
          SECTION: 'Tax objects'
        },
        MONTH: {
          SECTION: 'Months'
        },
        PERIODICITY: {
          SECTION: 'Periodicities'
        },
        RETENTION_PAYMENT_TYPE: {
          SECTION: 'Retention payments'
        },
        YEAR: {
          SECTION: 'Years'
        },
        RELATIONSHIP_TYPE: {
          SECTION: 'Relationship types'
        },
        EXPORT: {
          SECTION: 'Exports'
        },
        AGENT_NUMBER_TYPE: {
          SECTION: 'Authorized agents'
        },
        LOAD_TYPE: {
          SECTION: 'Load types'
        },
        BOAT_TYPE: {
          SECTION: 'Boat types'
        },
        AIR_TYPE: {
          SECTION: 'Carrier codes'
        },
        TRAILER_TYPE: {
          SECTION: 'Trailer or semi-trailer'
        },
        PERMISSION_TYPE: {
          SECTION: 'Type of permit provided by the SCT'
        },
        FEDERAL_TRUCKING_TYPE: {
          SECTION: 'Motor transport nomenclature key'
        },
        PACKAGING_UNIT: {
          SECTION: 'Measurement and packaging units'
        },
        PACKAGING_TYPE: {
          SECTION: 'Packaging catalogs'
        },
        PRODUCT_STCC_CATEGORY: {
          SECTION: 'STCC product catalog'
        },
        HAZARDOUS_CATEGORY: {
          SECTION: 'Hazardous materials catalog'
        },
        STATION_LIST: {
          SECTION: 'Station lists'
        },
        STATION: {
          SECTION: 'Stations'
        },
        TRANSPORT: {
          SECTION: 'Transports'
        },
        MERCHANDISE_MEASURE: {
          SECTION: 'Unit of measure'
        },
        TRANSFR_REASON: {
          SECTION: 'Transfer reason'
        },
        USECFDI: {
          SECTION: 'USE CFDI'
        },
        PRODUCTCATALOG: {
          SECTION: 'Product catalog'
        },
        MEASUREMENT: {
          SECTION: 'Measurement Unit'
        },
        ORIGINBANK: {
          SECTION: 'Bank Origin & destination'
        },
        TEMPLATE: {
          SECTION: 'Template'
        },
        EJERCICIOFISCAL: {
          SECTION: 'Ejercicio Fiscal'
        },
        EDUCATIONLEVEL: {
          SECTION: 'Education Level'
        },
        TYPEOFOPARETION: {
          SECTION: 'Type of operation'
        },
        PETTIONTYPE: {
          SECTION: 'Petition type'
        },
        INCOTERM: {
          SECTION: 'Incoterm'
        },
      },
      MASTER: {
        SECTION: 'Masters',
        MASTER: {
          SECTION: 'Users'
        },
        EXPIRY_MASTER: {
          SECTION: 'Expired users'
        }
      },
      EMPLOYEE: {
        SECTION: 'Employee',
        EMPLOYEE_JOB_TYPE: {
          SECTION: 'Job types'
        },
        EMPLOYEE_EXTRA_HOUR: {
          SECTION: 'Overtime'
        },
        EMPLOYEEPAYMENTPERIODTYPE: {
          SECTION: 'Payment period type'
        },
        EMPLOYEEJOBRISKTYPE: {
          SECTION: 'Job risk type'
        },
        EMPLOYEEOFFICIALJOBTYPE: {
          SECTION: 'Official job type'
        },
        EMPLOYEEEXPENSETYPE: {
          SECTION: 'Expense type'
        },
        EMPLOYEECONTRACTTYPE: {
          SECTION: 'Contract type'
        },
        EMPLOYEEDISABILITYTYPE: {
          SECTION: 'Disability type'
        },
        EMPLOYEEOTHERTYPE: {
          SECTION: 'Other type'
        },
        EMPLOYEEDISCOUNTTYPE: {
          SECTION: 'Discount type'
        },
        EMPLOYEE: {
          SECTION: 'Employees'
        },
        DEPARTMENT: {
          SECTION: 'Departments'
        },
        EXPENSE: {
          SECTION: 'Perceptions'
        },
        DISCOUNT: {
          SECTION: 'Deductions'
        },
        OTHER: {
          SECTION: 'Others'
        }
      },
      RETENTION: {
        SECTION: 'Retention',
        RETENTION_KEY: {
          SECTION: 'Rentention keys'
        },
        DISTRIBUTEDINCOMETYPE: {
          SECTION: 'Retention distributed key'
        },
        FEDERALENTITY: {
          SECTION: 'Retention federal entity'
        },
        RETENTIONTAXTYPE: {
          SECTION: 'Retention tax'
        },
        TAXPAYERSUBJECTTYPE: {
          SECTION: 'Retention subject type'
        },
        RETENTION_MODULE: 'Retention management 2.0',
        RETENTION: 'Retention 2.0',
        RETENTION_DRAFT: 'Retention draft 2.0',
        CANCELLED_RETENTION: 'Cancelled retention 2.0',
        FAILED_RETENTION: 'Failed retention 2.0',
        RETENTION_BATCH_MODULE: 'Retention batch 2.0',
        RETENTION_BATCH: 'Retention batch 2.0',
        RETENTION_BATCH_ADD: 'New constancia batch 2.0',
        CANCELLED_RETENTION_BATCH: 'Cancelled batch 2.0',
        FAILED_RETENTION_BATCH: 'Failed batch 2.0',
        RETENTION_RECEPTOR: 'Receivers Withholdings',
        RETENTION_NEW: 'New Constancia 2.0'
      },
      INVOICE_PREVIOUS: {
        INVOICE_MODULE: 'Invoice management 3.3',
        INVOICE: 'Invoice 3.3',
        LATEST_INVOICE: 'Latest invoice 3.3',
        LATEST_INVOICE_DRAFT: 'Latest invoice draft 3.3',
        INVOICE_BATCH: 'Invoice batch 3.3',
        INVOICE_BATCH_MODULE: 'Invoice batch 3.3',
        INVOICE_DRAFT: 'Invoice draft 3.3',
        CANCELLED_INVOICE: 'Cancelled invoice 3.3',
        CANCELLED_LATEST_INVOICE: 'Cancelled Latest invoice 3.3',
        FAILED_INVOICE: 'Failed invoice 3.3',
        FAILED_LATEST_INVOICE: 'Failed Latest invoice 3.3',
        CANCELLED_INVOICE_BATCH: 'Cancelled batch 3.3',
        FAILED_INVOICE_BATCH: 'Failed batch 3.3',
        bULK_INVOICE: 'Download invoice',
        BULK_INVOICE_BATCH: 'Download invoice batch',
        ADDENDA: 'Addenda',
        INVOICE_REPORT: 'Invoice report',
        INVOICE_BATCH_REPORT: 'Invoice batch report',
        INVOICE_PAYMENT_REPORT: 'Invoice payment report',
        INVOICE_PAYMENT_BATCH_REPORT: 'Invoice payment batch report',
        INVOICE_PAYMENT_MENU: 'Invoice payment report',
        INVOICE_PREMIUM_MENU: 'CFDI complement payment',
        INVOICE_PREMIUM_BATCH_MENU: 'Report plus Web payments',
        INVOICE_PAYMENT_BATCH_MENU: 'Report plus batch payments',
        NEW_INVOICE: 'New invoice',
        NEW_TAX_INVOICE: 'New invoice with taxes',
        NEW_BATCH: 'New invoice batch',
        NEW_STATIC_BATCH: 'New manual batch invoice',
        GET_CFDI_RESPONSE: 'CFDI status',
        TITLE: 'Invoice',
        ADD_TITLE: 'Add invoice',
        EDIT_TITLE: 'Edit invoice',
        DELETE_TITLE: 'Delete invoice',
        CANCEL_CONFIRMATION: 'Get CFDI Status',
        PAID_TITLE: 'Paid invoice',
        MANAGE_TITLE: 'Administrator',
        DRAFT_TITLE: 'Draft invoice',
      },
      INVOICE: {
        INVOICE_MODULE: 'Invoice management 4.0',
        INVOICE: 'Invoice 4.0',
        LATEST_INVOICE: 'Latest invoice 4.0',
        LATEST_INVOICE_DRAFT: 'Latest invoice draft 4.0',
        INVOICE_BATCH: 'Invoice batch',
        INVOICE_BATCH_ADD: 'Add invoice batch',
        INVOICE_BATCH_IEPS_ADD: 'New invoice IEPS batch 4.0',
        INVOICE_BATCH_MODULE: 'Invoice batch',
        INVOICE_DRAFT: 'Invoice draft 4.0',
        CANCELLED_INVOICE: 'Cancelled invoice 4.0',
        CANCELLED_LATEST_INVOICE: 'Cancelled Latest invoice 4.0',
        FAILED_INVOICE: 'Failed invoice 4.0',
        FAILED_LATEST_INVOICE: 'Failed Latest invoice 4.0',
        CANCELLED_INVOICE_BATCH: 'Cancelled batch',
        FAILED_INVOICE_BATCH: 'Failed batch',
        BULK_INVOICE: 'Download invoice',
        BULK_INVOICE_BATCH: 'Download invoice batch',
        ADDENDA: 'Addenda',
        INVOICE_REPORT: 'Invoice report',
        INVOICE_BATCH_REPORT: 'Invoice batch report',
        INVOICE_PAYMENT_REPORT: 'Invoice payment report',
        INVOICE_PAYMENT_BATCH_REPORT: 'Invoice payment batch report',
        INVOICE_PAYMENT_MENU: 'Invoice payment report',
        INVOICE_PREMIUM_MENU: 'CFDI complement payment',
        INVOICE_PREMIUM_BATCH_MENU: 'Report plus Web payments',
        INVOICE_PAYMENT_BATCH_MENU: 'Report plus batch payments',
        NEW_INVOICE: 'New invoice',
        NEW_TAX_INVOICE: 'New invoice with taxes',
        NEW_BATCH: 'New invoice batch',
        NEW_STATIC_BATCH: 'New manual batch invoice',
        GET_CFDI_RESPONSE: 'CFDI status',
        TITLE: 'Invoice',
        ADD_TITLE: 'Add invoice',
        EDIT_TITLE: 'Edit invoice',
        DELETE_TITLE: 'Delete invoice',
        CANCEL_CONFIRMATION: 'Get CFDI Status',
        PAID_TITLE: 'Paid invoice',
        MANAGE_TITLE: 'Administrator',
        DRAFT_TITLE: 'Draft invoice',
      },
      INVOICE_PERCENT: {
        INVOICE_MODULE: 'Invoice management IEPS 4.0',
        INVOICE: 'Invoice IEPS 4.0',
        INVOICE_DRAFT: 'Invoice draft IEPS 4.0',
        CANCELLED_INVOICE: 'Cancelled invoice IEPS 4.0',
        FAILED_INVOICE: 'Failed invoice IEPS 4.0'
      },
      PAYROLL: {
        PAYROLL_MODULE: 'Payroll management 4.0',
        PAYROLL: 'Payroll 4.0',
        PAYROLL_DRAFT: 'Payroll draft 4.0',
        CANCELLED_PAYROLL: 'Cancelled payroll 4.0',
        FAILED_PAYROLL: 'Failed payroll 4.0',
        PAYROLL_BATCH_MODULE: 'Payroll batch 4.0',
        PAYROLL_BATCH: 'Payroll batch 4.0',
        PAYROLL_BATCH_ADD: 'New batch receipt 4.0',
        PAYROLL_BATCH_CANCELLED: 'Cancelled batch 4.0',
        PAYROLL_BATCH_FAILED: 'Failed batch 4.0',
        NEW_PAYROLL: 'New payroll 4.0',
      },
      PAYROLL_PREVIOUS: {
        PAYROLL_MODULE: 'Payroll management 3.3',
        PAYROLL: 'Payroll 3.3',
        PAYROLL_DRAFT: 'Payroll draft 3.3',
        CANCELLED_PAYROLL: 'Cancelled payroll 3.3',
        FAILED_PAYROLL: 'Failed payroll 3.3',
        PAYROLL_BATCH_MODULE: 'Payroll batch 3.3',
        PAYROLL_BATCH: 'Payroll batch 3.3',
        PAYROLL_BATCH_CANCELLED: 'Cancelled batch 3.3',
        PAYROLL_BATCH_FAILED: 'Failed batch 3.3',
        NEW_PAYROLL: 'New payroll 3.3',
      },
      PAYROLL_MULTIPLE: {
        PAYROLL_MODULE: 'Payroll global',
        PAYROLL: 'Payroll global',
        PAYROLL_DRAFT: 'Payroll draft global',
        CANCELLED_PAYROLL: 'Cancelled payroll global',
        FAILED_PAYROLL: 'Failed payroll global',
        PAYROLL_BATCH_MODULE: 'Payroll batch global',
        PAYROLL_BATCH: 'Payroll batch global',
        PAYROLL_BATCH_ADD: 'New global batch receipt',
        PAYROLL_BATCH_CANCELLED: 'Cancelled batch global',
        PAYROLL_BATCH_FAILED: 'Failed batch global',
        NEW_PAYROLL: 'New payroll global',
      },
      RETENTION_PREVIOUS: {
        RETENTION_MODULE: 'Retention management 1.0',
        RETENTION: 'Retention 1.0',
        RETENTION_DRAFT: 'Retention draft 1.0',
        CANCELLED_RETENTION: 'Cancelled retention 1.0',
        FAILED_RETENTION: 'Failed retention 1.0',
        RETENTION_BATCH_MODULE: 'Retention batch 1.0',
        RETENTION_BATCH: 'Retention batch 1.0',
        CANCELLED_RETENTION_BATCH: 'Cancelled batch 1.0',
        FAILED_RETENTION_BATCH: 'Failed batch 1.0',
      },
      CUSTOMER_REPORT: {
        REPORT: 'Reports',
        PAYROLL_BATCH_REPORT: 'Payroll batch report',
        PAYROLL_REPORT: 'Payroll report',
        MULTIPLE_PAYROLL_BATCH_REPORT: 'Global payroll batch report',
        MULTIPLE_PAYROLL_REPORT: 'Global payroll report',
        RETENTION_BATCH_REPORT: 'Retention batch report',
        RETENTION_REPORT: 'Retention report',
        INVOICE_BATCH_REPORT: 'Invoice batch report',
        INVOICE_REPORT: 'Invoice report',
        INVOICE_PAYMENT_BATCH_REPORT: 'Payment batch report',
        INVOICE_PAYMENT_REPORT: 'Payment report',
        INVOICE_PREMIUM_BATCH_REPORT: 'Premium report batch',
        INVOICE_PREMIUM_REPORT: 'Premium report',
        DOWNLOAD_INVOICE_TITLE: 'Download invoice',
        DOWNLOAD_INVOICE_BATCH_TITLE: 'Download invoice batch',
        DOWNLOAD_PAYROLL_TITLE: 'Download payroll',
        DOWNLOAD_PAYROLL_BATCH_TITLE: 'Download payroll batch',
        DOWNLOAD_RETENTION_TITLE: 'Download retention',
        DOWNLOAD_RETENTION_BATCH_TITLE: 'Download retention batch',
      },
      OPERATOR: {
        TITLE: 'Users'
      },
      COMPANY: {
        SECTION: 'Company'
      },
      CUSTOMER: {
        SECTION: 'Customer'
      },
      PRODUCT: {
        SECTION: 'Product'
      },
      PART: {
        SECTION: 'Part'
      },
      TAX: {
        SECTION: 'Tax'
      },
      ADDENDA: {
        SECTION: 'Addenda'
      },
      AUTOMATIC: {
        SECTION: 'Automatic invoice 4.0',
        ADD: {
          SECTION: 'Add F.A. 4.0'
        },
        LIST: {
          SECTION: 'A. I. 4.0'
        },
        FAILED_LIST: {
          SECTION: 'Failed A. I. 4.0'
        },
        CANCELLED_LIST: {
          SECTION: 'Cancelled A. I. 4.0'
        },
        REPORT_HEADING: 'Reports',
        BATCH_REPORT: 'FA Reports',
        PAYMENT_BATCH_REPORT: 'FA Payment Reports',
        DOWNLOAD_BATCH_REPORT: 'Download F.A.',
        FAILED_BATCH_REPORT: 'Failed Reports',
      },
      AUTOMATIC_PREVIOUS: {
        SECTION: 'Automatic invoice',
        LIST: {
          SECTION: 'A. I.'
        },
        FAILED_LIST: {
          SECTION: 'Failed A. I.'
        },
        CANCELLED_LIST: {
          SECTION: 'Cancelled A. I.'
        }
      },
      REPORT: {
        SECTION: 'Reports',
        AUTOMATIC_INVOICE_REPORT: {
          SECTION: 'Report A. I.'
        },
        AUTOMIATIC_INVOICE_PAYMENT_REPORT: {
          SECTION: 'Report payment A. I.'
        },
        AUTOMATIC_INVOICE_DOWNLOAD: {
          SECTION: 'Download A.I.'
        },
        AUTOMATIC_INVOICE_FAILED_REPORT: {
          SECTION: 'Report Failed A.I.'
        },
        AUTOMATIC_INVOICE_SPECIAL_REPORT: {
          SECTION: 'Reporte special'
        }
      },
      APPS: {
        SECTION: 'Apps & pages',
        EMAIL: 'Email',
        CHAT: 'Chat',
        TODO: 'Todo',
        CALENDAR: 'Calendar',
        INVOICE: {
          COLLAPSIBLE: 'Invoice',
          LIST: 'List',
          PREVIEW: 'Preview',
          EDIT: 'Edit',
          ADD: 'Add'
        },
        ECOMMERCE: {
          COLLAPSIBLE: 'eCommerce',
          SHOP: 'Shop',
          DETAIL: 'Details',
          WISHLIST: 'Wish List',
          CHECKOUT: 'Checkout'
        },
        USER: {
          COLLAPSIBLE: 'User',
          LIST: 'List',
          VIEW: 'View',
          EDIT: 'Edit'
        }
      },
      PAGES: {
        SECTION: 'Pages',
        AUTH: {
          COLLAPSIBLE: 'Authentication',
          LOGIN1: 'Login V1',
          LOGIN2: 'Login V2',
          REGISTER1: 'Register V1',
          REGISTER2: 'Register V2',
          FORGOTPASSWORD1: 'Forgot password V1',
          FORGOTPASSWORD2: 'Forgot password V2',
          RESETPASSWORD1: 'Reset password V1',
          RESETPASSWORD2: 'Reset password V2'
        },
        ACCOUNTSETTINGS: 'Account Settings',
        PROFILE: 'Profile',
        FAQ: 'FAQ',
        KB: 'Knowledge base',
        PRICING: 'Pricing',
        BLOG: {
          COLLAPSIBLE: 'Blog',
          LIST: 'List',
          DETAILS: 'Details',
          EDIT: 'Edit'
        },
        MAIL: {
          COLLAPSIBLE: 'Mail template',
          WELCOME: 'Welcome',
          RESET: 'Reset password',
          VERIFY: 'Verify',
          DEACTIVATE: 'Deactivate',
          INVOICE: 'Invoice',
          PROMOTIONAL: 'Promotional'
        },
        MISC: {
          COLLAPSIBLE: 'Miscellaneous',
          COMINGSOON: 'Coming Soon',
          NOTAUTH: 'Not Authorized',
          MAINTENANCE: 'Maintenance',
          ERROR: 'Error'
        }
      },
      UI: {
        SECTION: 'User interface',
        TYPOGRAPHY: 'Typography',
        COLORS: 'Colors',
        FEATHER: 'Feather',
        CARDS: {
          COLLAPSIBLE: 'Cards',
          BADGE: 'New',
          BASIC: 'Basic',
          ADVANCE: 'Advance',
          STATISTICS: 'Statistics',
          ANALYTICS: 'Analytics',
          ACTIONS: 'Actions'
        },
        COMPONENTS: {
          COLLAPSIBLE: 'Components',
          ALERTS: 'Alerts',
          AVATAR: 'Avatar',
          BADGES: 'Badges',
          BREADCRUMBS: 'Breadcrumbs',
          BUTTONS: 'Buttons',
          CAROUSEL: 'Carousel',
          COLLAPSE: 'Collapse',
          DIVIDER: 'Divider',
          DROPDOWNS: 'Dropdowns',
          GROUP: 'List Group',
          OBJECTS: 'Media Objects',
          MODALS: 'Modals',
          COMPONENT: 'Navs',
          PAGINATION: 'Pagination',
          PBADGES: 'Pill badges',
          PILLS: 'Pills',
          POPOVERS: 'Popovers',
          PROGRESS: 'Progress',
          RATINGS: 'Ratings',
          SPINNER: 'Spinner',
          TABS: 'Tabs',
          TIMELINE: 'Timeline',
          TOASTS: 'Toasts',
          TOOLTIPS: 'Tooltips'
        },
        EX: {
          COLLAPSIBLE: 'Extension',
          SWEET_ALERTS: 'Sweet Alerts',
          BLOCKUI: 'BlockUI',
          TOASTER: 'Toastr',
          SLIDER: 'Slider',
          DRAGDROP: 'Drag & drop',
          TOUR: 'Tour',
          CLIPBOARD: 'Clipboard',
          MEDIAPLAYER: 'Media player',
          CONTEXTMENU: 'Context menu',
          SWIPER: 'Swiper',
          TREEVIEW: 'Tree View',
          I18N: 'I18n'
        },
        LAYOUTS: {
          COLLAPSIBLE: 'Page Layouts',
          COLLAPSED_MENU: 'Collapsed menu',
          BOXED_LAYOUT: 'Boxed Layout',
          WITHOUT_MENU: 'Without menu',
          LAYOUT_EMPTY: 'Layout Empty',
          LAYOUT_BLANK: 'Layout blank'
        }
      },
      FT: {
        SECTION: 'Forms & tables',
        ELEMENT: {
          COLLAPSIBLE: 'Form Elements',
          INPUT: 'Input',
          INPUTGROUPS: 'Input Groups',
          INPUTMASK: 'Input mask',
          TEXTAREA: 'Textarea',
          CHECKBOX: 'Checkbox',
          RADIO: 'Radio',
          SWITCH: 'Switch',
          SELECT: 'Select',
          NUMBERINPUT: 'Number input',
          FILEUPLOADER: 'File Uploader',
          QUILLEDITOR: 'Quill Editor',
          FLATPICKER: 'Flatpicker',
          DATETIMEPICKER: 'Date & time picker'
        },
        LAYOUTS: 'Form Layouts',
        WIZARD: 'Form Wizard',
        VALIDATION: 'Form Validations',
        REPEATER: 'Form repeater',
        TABLE: 'Table',
        DATATABLES: 'DataTables'
      },
      CM: {
        SECTION: 'Charts & maps',
        CHARTS: {
          COLLAPSIBLE: 'Charts',
          BADGE: '2',
          APEX: 'Apex',
          CHARTJS: 'ChartJS'
        },
        MAPS: 'Google maps'
      },
      OTHERS: {
        SECTION: 'Others',
        LEVELS: {
          COLLAPSIBLE: 'Menu Levels',
          SECOND: 'Second Level',
          SECOND1: {
            COLLAPSIBLE: 'Second Level',
            THIRD: 'Third Level',
            THIRD1: 'Third Level'
          }
        },
        DISABLED: 'Disabled menu',
        DOCUMENTATION: 'Documentation',
        SUPPORT: 'Raise Support'
      }
    }
  }
};
